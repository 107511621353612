import { Box } from '@mui/material';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import { SPEND_AMOUNT_ANSWERS, SpendAmount } from '../../../providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step7() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: SpendAmount) => {
    setQuizData((prev) => ({
      ...prev,
      spendAmount: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={7}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="How much do you typically spend on alcohol each week?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(SPEND_AMOUNT_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof SPEND_AMOUNT_ANSWERS)}
              variant={ButtonType.WHITE}
              text={SPEND_AMOUNT_ANSWERS[key as keyof typeof SPEND_AMOUNT_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step7;
