import { Box, Button, SxProps, Theme, useMediaQuery } from '@mui/material';
import checked_icon from '../../assets/svg/checked_icon.svg';
import { isOpenedInTelegram } from '@utils/browser';

export enum ButtonType {
  WHITE = 'white',
  ORANGE = 'orange',
  ORANGE_FIXED = 'red_fixed',
  CHECKBOX = 'checkbox',
  CHECKBOX_SELECTED = 'checkbox_selected',
  APPLE = 'apple',
}

function QuizButton({
  text,
  variant,
  onClick,
  disabled,
  height,
  padding,
  children,
  type,
  className,
}: {
  text: string;
  variant: ButtonType;
  onClick: () => void;
  disabled?: boolean;
  height?: string;
  padding?: string;
  children?: JSX.Element;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const getOverrideButtonStyles = () => {
    switch (variant) {
      case ButtonType.WHITE: {
        return {
          backgroundColor: '#FFF',
          fontFamily: 'Avenir600',
          color: '#282624',
          '&:hover': {
            backgroundColor: isMobile ? '#FFF' : '#FFF',
          },
        };
      }
      case ButtonType.ORANGE: {
        return {
          backgroundColor: '#ED744E',
          color: '#fff',
          fontFamily: 'Avenir600',
          '&:hover': {
            backgroundColor: '#ED744E',
            opacity: isMobile ? 1 : 0.8,
          },
          '&:disabled': {
            backgroundColor: '#F9E9E0',
            color: '#FFF',
          },
        };
      }
      case ButtonType.ORANGE_FIXED: {
        return {
          backgroundColor: '#ED744E',
          color: '#fff',
          fontFamily: 'Avenir500',
          position: 'fixed',
          borderRadius: 0,
          bottom: isOpenedInTelegram() ? '80px' : 0,
          left: '50%',
          transform: 'translateX(-50%)',
          '&:hover': {
            backgroundColor: '#ED744E',
            opacity: isMobile ? 1 : 0.8,
          },
          '&:disabled': {
            backgroundColor: 'rgba(143, 154, 203, 0.20)',
            color: 'rgba(143, 154, 203, 0.40)',
          },
        };
      }
      case ButtonType.CHECKBOX: {
        return {
          backgroundColor: '#FFF',
          color: '#282624',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: isMobile ? '#FFF' : '#FFF',
          },
        };
      }
      case ButtonType.CHECKBOX_SELECTED: {
        return {
          backgroundColor: '#FFF',
          color: '#282624',
          justifyContent: 'space-between',
          fontFamily: 'Avenir500',
          '&:hover': {
            backgroundColor: '#FFF',
            opacity: isMobile ? 1 : 0.8,
          },
          '&:disabled': {
            backgroundColor: '#FFF',
            color: '#282624',
          },
        };
      }
      case ButtonType.APPLE: {
        return {
          backgroundColor: '#282624',
          '&:hover': {
            backgroundColor: '#282624',
            opacity: isMobile ? 1 : 0.8,
          },
          '&:disabled': {
            backgroundColor: 'rgba(40, 38, 36, 0.8)',
          },
        };
      }
    }
  };
  const getEndIcon = () => {
    switch (variant) {
      case ButtonType.CHECKBOX: {
        return (
          <Box
            sx={{
              border: '1px solid #F0CCB9',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
            }}
          ></Box>
        );
      }
      case ButtonType.CHECKBOX_SELECTED: {
        return <img src={checked_icon} alt="checked"></img>;
      }
      default: {
        return undefined;
      }
    }
  };

  return (
    <Button
      className={className}
      disabled={disabled}
      endIcon={getEndIcon()}
      fullWidth
      type={type}
      sx={{
        position: 'relative',
        fontFamily: 'Avenir500',
        fontSize: '16px',
        textTransform: 'none',
        height: height ?? '60px',
        borderRadius: '12px',
        transition: 'all 0.3s ease',
        padding: padding ?? '0 18px',
        justifyContent: 'center',
        zIndex: 2,
        border:
          variant === ButtonType.CHECKBOX_SELECTED ? '1px solid #ED744E' : '',
        ...getOverrideButtonStyles(),
      }}
      onClick={onClick}
    >
      {text}
      {children}
    </Button>
  );
}

export default QuizButton;
