import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../App';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { getPathWithSearchParams } from '../../../utils/ulr';

function Step1({
  stepNumber = 1,
  stepsNumber = 19,
}: {
  stepNumber?: number;
  stepsNumber?: number;
}) {
  const navigate = useNavigate();
  const { setStep, setQuizData, quizData } = useQuizState();

  const handleChange = (name: string) => {
    setQuizData((prev) => ({
      ...prev,
      name: name,
    }));
  };

  const handleClick = () => {
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          navigate(getPathWithSearchParams(Routes.MAIN));
        }}
        stepNumber={stepNumber}
        stepsNumber={stepsNumber}
      />
      <QuizQuestion
        text="Choose Your Name"
        description="Whether it's a nickname, your first name, or a creative alias, we'll use it to personalize your plan."
      />
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '& input': {
              width: 'fill-available',
              background: '#FFF',
              border: '1px solid #F0CCB9',
              borderRadius: '12px',
              fontFamily: 'Avenir500',
              padding: '19px',
              fontSize: '16px',
              lineHeight: 'normal',
              color: '#282624',
              '&:focus-visible': {
                border: '1px solid #F0CCB9',
                outline: 0,
              },
              '::placeholder': {
                color: '#908C89',
              },
            },
          }}
        >
          <input
            placeholder="What can we call you?"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          <QuizButton
            onClick={() => {
              handleClick();
            }}
            disabled={!(quizData.name.length > 2)}
            variant={ButtonType.ORANGE}
            text="Continue"
          />
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step1;
