import { Box, Typography } from '@mui/material';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Habits() {
  const { setStep, quizData } = useQuizState();
  const handleClick = () => {
    setStep((prev) => prev + 1);
  };
  return (
    <QuizStepBackgroundWrapper>
      <QuizContainer>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <img src="/refuse/images/quiz/habits.webp" width={211} height={180} />
          <Box>
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                textAlign: 'center',
                color: '#ED744E',
                marginBottom: '10px',
                fontSize: '15px',
              }}
            >
              Explore Your Current Habits
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: '#282624',
                fontSize: '24px',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              To tailor your personalized plan, let's take a closer look at your
              current habits and routines
            </Typography>
          </Box>
          <QuizButton
            variant={ButtonType.ORANGE}
            text="Continue"
            onClick={handleClick}
          />
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Habits;
