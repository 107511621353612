export interface SubscribeRecurlySuccess {
  id: string;
  success: boolean;
  account: {
    code: string;
  };
}

export interface ActivateSubscribeRecurlySuccess {
  success: boolean;
}

export interface SubscribeRecurlyError {
  error: string;
}

export interface SubscribeRecurlySecure3d {
  actionTokenId: string;
  code: string;
}
export function isSubscribeRecurlySuccessResponse(
  response: Record<string, any>
): response is SubscribeRecurlySuccess {
  return typeof response.id === 'string';
}

export function isActiveSubscribeRecurlySuccessResponse(
  response: Record<string, any>
): response is ActivateSubscribeRecurlySuccess {
  return response.success === true;
}

export function isSubscribeRecurlyErrorResponse(
  response: Record<string, any>
): response is SubscribeRecurlyError {
  return typeof response.error === 'string' && response.error !== '';
}

export function isSubscribeRecurlySecure3dResponse(
  response: Record<string, any>
): response is SubscribeRecurlySecure3d {
  return (
    typeof response.actionTokenId === 'string' &&
    typeof response.code == 'string'
  );
}
