import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo.svg';
import QuizButton, { ButtonType } from '../../common/QuizButton';

function Header() {
  const texts = [
    'Learn about the neuroscience of alcohol and the psychology behind drinking to empower your change.',
    'Log your drinks and gain personalized insights to better understand and refine your drinking habits.',
    'Access round-the-clock support and guidance from our AI companion to keep you on track anytime, anywhere.',
    'Change your perspective on alcohol and life with focused mindset strategies.',
    'Develop healthy habits with actionable steps and positive psychology techniques.',
    'Integrate nutrition, movement, creativity, and more to replace alcohol use and enjoy a fulfilling lifestyle.',
  ];
  return (
    <Box
      sx={{
        maxWidth: '460px',
        margin: 'auto',
        padding: '0 20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '17px',
        }}
      >
        <img width={'26px'} height={'26px'} src={logo} alt="logo"></img>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '14px',
            userSelect: 'none',
            color: '#282624',
          }}
        >
          Refuse
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '24px',
          color: '#282624',
          userSelect: 'none',
          textAlign: 'center',
          lineHeight: '32px',
          margin: '20px 0 20px',
          '& .orange': {
            color: '#ED744E',
          },
        }}
      >
        The time is now to{' '}
        <span className="orange">transform your relationship with alcohol</span>{' '}
        for good
      </Typography>
      <Box
        sx={{
          marginBottom: '20px',
          '& img': {
            maxWidth: '100%',
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            paddingTop: '57.38%',
            position: 'relative',
            '& > img': {
              position: 'absolute',
              top: '0',
              left: '-4px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          <img
            draggable={false}
            src="/refuse/images/subscription/results.svg"
            alt="results"
          ></img>
        </Box>
      </Box>
      <QuizButton
        onClick={() => {
          const plans = document.getElementById('get_your_plan');
          if (plans) {
            plans.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        variant={ButtonType.ORANGE}
        text="Get rid of addiction"
      ></QuizButton>
      <Box
        sx={{
          height: '1px',
          margin: '40px 0',
          background: 'rgba(240, 204, 185, 0.6)',
          width: '100%',
        }}
      ></Box>
      <Typography
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#282624',
          margin: '30px 0',
          userSelect: 'none',
          '& .orange': {
            color: '#ED744E',
          },
        }}
      >
        Your journey to a <span className="orange">healthier, happier</span> you
        begins today with a 60-day personalised program:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
          '& div': {
            display: 'flex',
            gap: '15px',
            '& img': {
              width: '32px',
              height: '32px',
            },
            '& p': {
              fontFamily: 'Avenir400',
              fontSize: '16px',
              lineHeight: '22px',
              color: '#282624',
            },
          },
        }}
      >
        {texts.map((text) => (
          <Box key={text}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src="/refuse/svg/checkmark.svg" alt="checkmark"></img>
            </Box>
            <Typography>{text}</Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          height: '1px',
          margin: '40px 0',
          background: 'rgba(240, 204, 185, 0.6)',
          width: '100%',
        }}
      ></Box>
    </Box>
  );
}

export default Header;
