export function setParamsToSessionStorage(params: Record<string, any>) {
  Object.entries(params).forEach(([key, value]) => {
    window.sessionStorage.setItem(`__${key}`, value);
  });
}

export function getParamsFromSessionStorage(params: Array<string>) {
  const result: Record<string, any> = {};

  params.forEach((key) => {
    const value = window.sessionStorage.getItem('__' + key);

    if (value) {
      result[key] = value;
    }
  });

  return result;
}
