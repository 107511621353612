import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import { FAMILY_HISTORY_ANSWERS, FamilyHistoryOfHeartIssues, Frequency, FREQUENCY_ANSWERS } from '../../../providers/QuizProvider/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step9() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: Frequency) => {
    setQuizData((prev) => ({
      ...prev,
      frequency: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={9}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="How frequently do you wake up with the negative effects of alcohol, such as headaches or low energy?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(FREQUENCY_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof FREQUENCY_ANSWERS)}
              variant={ButtonType.WHITE}
              text={FREQUENCY_ANSWERS[key as keyof typeof FREQUENCY_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step9;
