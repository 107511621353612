import { Box, Typography, useMediaQuery } from '@mui/material';
import logo from '../../../assets/images/main/logo_huge.svg';
import logo_footer from '../../../assets/images/main/footer_logo.svg';
import leaf from '../../../assets/images/main/mask_group.webp';
import StartTheQuizButton from './common/StartTheQuizButton';
import TermsAndConditions from './common/TermsAndConditions';
import DownloadAppButton from './common/DownloadAppButton';
import { APP_CONSTANTS } from '@utils/constants';

function Footer() {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Box
      sx={{
        backgroundColor: '#112417',
        padding: '60px 20px 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {!isMobile && <img width={242} height={412} src={leaf} alt="leaf"></img>}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '536px',
          }}
        >
          <img width={isMobile ? 75 : 120} src={logo} alt="logo"></img>

          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Avenir600',
              fontSize: isMobile ? '24px' : '40px',
              color: '#FFF',
              lineHeight: 'normal',
              margin: isMobile ? '20px 0' : '30px 0',
            }}
          >
            The time is now to transform your relationship with alcohol for good
          </Typography>
          <Box
            sx={{
              width: isMobile ? '100%' : '320px',
            }}
          >
            <StartTheQuizButton />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Avenir400',
              fontSize: '16px',
              color: '#728879',
              lineHeight: 'normal',
              marginTop: isMobile ? '20px' : '30px',
            }}
          >
            Complete a brief questionnaire so we can create a personalized plan tailored to you
          </Typography>
        </Box>
        {!isMobile && <img style={{ transform: 'scaleX(-1)' }} width={242} height={412} src={leaf} alt="leaf"></img>}
      </Box>
      <Box
        sx={{
          height: '1px',
          width: '100%',
          background: '#1E3927',
          margin: isMobile ? '60px 0 40px' : '100px 0 40px',
        }}
      ></Box>
      {isMobile ? (
        <Box>
          <TermsAndConditions isMainScreen />
          <Box
            sx={{
              margin: '20px 0',
            }}
          >
            <DownloadAppButton />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Avenir400',
              fontSize: '14px',
              color: '#728879',
              lineHeight: 'normal',
            }}
          >
            {APP_CONSTANTS.APP_NAME} by {APP_CONSTANTS.COMPANY_NAME} © 2024 | {APP_CONSTANTS.ADDRESS}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '40px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <img width={138} src={logo_footer} alt="logo"></img>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  color: '#728879',
                  lineHeight: 'normal',
                }}
              >
                {APP_CONSTANTS.APP_NAME} by {APP_CONSTANTS.COMPANY_NAME} © 2024 | {APP_CONSTANTS.ADDRESS}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  '& .download_app_btn': {
                    width: '155px',
                    height: '46px',
                  },
                }}
              >
                <DownloadAppButton />
              </Box>
              <TermsAndConditions isMainScreen />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Footer;
