import React from 'react';
import { Box, Typography } from '@mui/material';

import features from '../../../assets/svg/features.svg';
import person_1 from '../../../assets/images/subscription/lisa.webp';
import person_2 from '../../../assets/images/subscription/jacob.webp';
import person_3 from '../../../assets/images/subscription/robert.webp';
import FireImage from '../../../assets/images/subscription/fire.webp';
import WreathLeft from '../../../assets/images/subscription/wreath-left.webp';
import WreathRight from '../../../assets/images/subscription/wreath-right.webp';
import reviewStars from '../../../assets/svg/review.svg';

import ChoosePlan from './ChoosePlan';

function MainContent() {
  const reviews = [
    {
      name: 'Lisa',
      text: 'Refuse has completely transformed my relationship with alcohol. The personalized plan and 24/7 AI companion have been game-changers in my journey to a healthier lifestyle.',
      image: person_1,
    },
    {
      name: 'Jacob',
      text: "I love how Refuse combines science-backed insights with practical strategies. The habit tracking feature keeps me accountable, and I've never felt more in control of my drinking habits.",
      image: person_2,
    },
    {
      name: 'Robert Fox',
      text: "The best part about this App is that you can get your heart health measurements anytime and place them through the phone. It provides fascinating insights into how my body works, which I didn't know before!",
      image: person_3,
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '460px',
        margin: 'auto',
        padding: '0 20px',
        '& img.fire-image': {
          maxWidth: '160px',
          width: '100%',
          margin: '0 auto 15px',
        },
      }}
    >
      <img
        draggable={false}
        className="fire-image"
        src="/refuse/images/subscription/fire.webp"
        alt="fire"
      />
      <Typography
        id="get_your_plan"
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '32px',
          lineHeight: 'normal',
          userSelect: 'none',
          textAlign: 'center',
          color: '#282624',
          '& .orange': {
            color: '#ED744E',
          },
        }}
      >
        Get your personal plan with <span className="orange">discount</span>
      </Typography>
      <ChoosePlan />
      <Box
        sx={{
          height: '1px',
          margin: '40px 0',
          background: 'rgba(143, 154, 203, 0.20)',
          width: '100%',
        }}
      ></Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          '& .wreath': {
            position: 'absolute',
            maxWidth: '78px',
          },
          '& .wreath-right': {
            right: 0,
          },
          '& .wreath-left': {
            left: 0,
          },
        }}
      >
        <img
          draggable={false}
          src={WreathLeft}
          className="wreath wreath-left"
          alt="wreath"
        ></img>
        <Box sx={{ maxWidth: '221px', zIndex: 2 }}>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '24px',
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              color: '#282624',
            }}
          >
            Join the users we have helped
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '15px 0',
            }}
          >
            <img draggable={false} src={features} alt="features"></img>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '14px',
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              color: '#908C89',
            }}
          >
            more than 10k reviews
          </Typography>
        </Box>
        <img
          draggable={false}
          src={WreathRight}
          className="wreath wreath-right"
          alt="wreath"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
          padding: '30px 0 40px',
        }}
      >
        {reviews.map((review, index) => (
          <Box
            key={index}
            sx={{
              background: 'white',
              borderRadius: '16px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: ' 20px',
              boxShadow: '0px 0px 8px 0px #DFD2C933',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  userSelect: 'none',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    lineHeight: 'normal',
                    color: '#282624',
                    userSelect: 'none',
                    marginBottom: '5px',
                  }}
                >
                  {review.name}
                </Typography>
                <img draggable={false} src={reviewStars} alt="review"></img>
              </Box>
              <Box
                sx={{
                  borderRadius: '50%',
                  overflow: 'hidden',
                  width: '52px',
                  height: '52px',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${review.image})`,
                }}
              ></Box>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '14px',
                lineHeight: 'normal',
                userSelect: 'none',
                color: '#282624',
              }}
            >
              {review.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default MainContent;
