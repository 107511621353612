import { Box } from '@mui/material';
import { useState } from 'react';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import {
  Areas,
  AREAS_ANSWERS,
} from '../../../providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step4({
  stepNumber = 4,
  stepsNumber = 19,
}: {
  stepNumber?: number;
  stepsNumber?: number;
}) {
  const { setStep, setQuizData } = useQuizState();
  const [selectedValues, setSelectedValues] = useState<Areas[]>([]);

  const handleClick = (type: Areas) => {
    const filteredValues = [...selectedValues];
    const isExists = filteredValues.find((el) => el === type);
    if (!isExists) {
      setSelectedValues([...filteredValues, type]);
    } else {
      const newValues = filteredValues.filter((el) => el !== type);
      setSelectedValues(newValues);
    }
  };

  const handleContinue = () => {
    setQuizData((prev) => ({
      ...prev,
      areas: selectedValues,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={stepNumber}
        stepsNumber={stepsNumber}
      ></QuizHeader>
      <QuizQuestion text="Are there any other areas you want to focus on during your journey?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            paddingBottom: '80px',
            '& .text-align-left': {
              textAlign: 'left',
            },
          }}
        >
          {Object.keys(AREAS_ANSWERS).map((key) => (
            <QuizButton
              className="text-align-left"
              key={key}
              onClick={() => handleClick(key as keyof typeof AREAS_ANSWERS)}
              variant={
                selectedValues.find((el) => el === key)
                  ? ButtonType.CHECKBOX_SELECTED
                  : ButtonType.CHECKBOX
              }
              text={AREAS_ANSWERS[key as keyof typeof AREAS_ANSWERS]}
            />
          ))}
        </Box>
        {!!selectedValues.length && (
          <QuizButton
            variant={ButtonType.ORANGE_FIXED}
            text={'Continue'}
            onClick={handleContinue}
          />
        )}
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step4;
