import { Box, Typography, useMediaQuery } from '@mui/material';
import img1 from '../../../assets/images/main/6.webp';
import img2 from '../../../assets/images/main/7.webp';
import img3 from '../../../assets/images/main/8.webp';
import img4 from '../../../assets/images/main/9.webp';
import img5 from '../../../assets/images/main/10.webp';

function WhatYouGet() {
  const isMobile = useMediaQuery('(max-width:900px)');

  const arr = [
    {
      title: 'Personalized Program',
      subtitle:
        'Our program is designed to match your habits and lifestyle, fitting your unique goals and needs for long-term success.',
      image: img1,
    },
    {
      title: '24/7 AI Supports',
      subtitle:
        'Watch you drinking habits change and celebrate your drink-free journey. Stay consistent and get instant support whenever you feel a craving',
      image: img2,
    },
    {
      title: 'Learn to live better',
      subtitle:
        'Discover everything about healthy lifestyle, mindfulness, and psychology via comprehensive blogs to support the habit change that stays. ',
      image: img3,
    },
    {
      title: 'Science-Backed Approach',
      subtitle:
        'Understand the neuroscience of drinking with evidence-based strategies. Take a look at drinking habbits from another angle and use knowledge.',
      image: img4,
    },
    {
      title: 'Proven approach that really works',
      subtitle:
        'The proof is in the data! Even when compared to other apps, Refuse users reported a significantly lower amount of alcohol consumption.',
      image: img5,
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        padding: '60px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        id="whatYouGetAnchor"
        sx={{
          scrollMargin: '84px',
          textAlign: 'center',
          fontFamily: 'Avenir500',
          fontSize: isMobile ? '16px' : '20px',
          color: '#ED744E',
          lineHeight: 'normal',
        }}
      >
        What do you get?
      </Typography>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir600',
          fontSize: isMobile ? '32px' : '56px',
          color: '#282624',
          lineHeight: 'normal',
          maxWidth: '820px',
          margin: '15px 0 40px',
        }}
      >
        Refuse is packed with smart features that help
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        {arr.map((el) => (
          <Box
            key={el.title}
            sx={{
              width: isMobile ? undefined : 'calc(50% - 10px)',
              maxWidth: '816px',
              background: '#FCF4EF',
              borderRadius: '30px',
              padding: '30px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& img': {
                width: '100%',
                maxWidth: '560px',
              },
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Avenir600',
                fontSize: isMobile ? '24px' : '32px',
                color: '#282624',
                margin: '0 20px',
                lineHeight: 'normal',
              }}
            >
              {el.title}
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Avenir400',
                fontSize: isMobile ? '16px' : '20px',
                color: '#908C89',
                lineHeight: 'normal',
                margin: isMobile ? '10px 20px 20px' : '10px 40px 20px',
              }}
            >
              {el.subtitle}
            </Typography>
            <img src={el.image} alt="advantages"></img>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default WhatYouGet;
