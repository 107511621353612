import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo.svg';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Chart() {
  const { setStep, quizData } = useQuizState();
  const handleClick = () => {
    setStep((prev) => prev + 1);
  };
  return (
    <QuizStepBackgroundWrapper>
      <QuizContainer>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: '30px',
            gap: '20px',
          }}
        >
          <img src={logo} width={72} height={72} />
          <Box>
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                textAlign: 'center',
                color: '#ED744E',
                marginBottom: '10px',
                fontSize: '15px',
              }}
            >
              Based on your answers
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: '#282624',
                fontSize: '24px',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              We're creating a custom Refuse program for you to help you achieve
              goals and change your habbits
            </Typography>
          </Box>
          <img src="/refuse/images/quiz/Chart.webp" width={'100%'} />
          <QuizButton
            variant={ButtonType.ORANGE}
            text="Continue"
            onClick={handleClick}
          />
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Chart;
