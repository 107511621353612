import { Box, Typography, useMediaQuery } from '@mui/material';
import chart from '../../../assets/images/main/chart.svg';
import person_1 from '../../../assets/images/subscription/lisa.webp';
import person_2 from '../../../assets/images/subscription/jacob.webp';
import person_3 from '../../../assets/images/subscription/robert.webp';
import reviewStars from '../../../assets/svg/review.svg';
import { useEffect } from 'react';

const reviews = [
  {
    name: 'Jacob',
    text: (
      <>
        I love how <span className="color">Refuse</span> combines science-backed insights with practical strategies. The
        habit tracking feature keeps me accountable, and I've never felt more in control of my drinking habits.
      </>
    ),
    image: person_2,
  },
  {
    name: 'Lisa',
    text: (
      <>
        <span className="color">Refuse</span> has completely transformed my relationship with alcohol. The personalized
        plan and 24/7 AI companion have been game-changers in my journey to a healthier lifestyle.
      </>
    ),
    image: person_1,
  },
  {
    name: 'Robert Fox',
    text: "The best part about this App is that you can get your heart health measurements anytime and place them through the phone. It provides fascinating insights into how my body works, which I didn't know before!",
    image: person_3,
  },
];

function Reviews() {
  const width = window.innerWidth > 460 ? 460 : window.innerWidth;
  const isMobile = useMediaQuery('(max-width:900px)');
  const isLess1250 = useMediaQuery('(max-width:1250px)');

  useEffect(() => {
    const wrapperDiv = document.getElementById('review_wrapper');
    if (wrapperDiv && isMobile) {
      const scrollTo = wrapperDiv.scrollWidth / 2;
      wrapperDiv.scrollTo({
        left: scrollTo - width / 2,
      });
    }
  }, [isMobile]);

  return (
    <Box
      sx={{
        backgroundColor: '#112417',
      }}
    >
      <Box
        id="review_wrapper"
        sx={{
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          sx={{
            padding: '60px 20px',
            width: isLess1250 ? 'min-content' : '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'center',
            }}
          >
            {reviews.map((review, index) => (
              <Box
                key={index}
                sx={{
                  background: 'white',
                  borderRadius: '16px',
                  padding: '30px 20px 20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '40px',
                  boxShadow: '0px 0px 8px 0px #DFD2C933',
                  width: '100%',
                  minWidth: `calc(${width - 76}px)`,
                  maxWidth: '576px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: isMobile ? '20px' : '24px',
                    lineHeight: 'normal',
                    userSelect: 'none',
                    color: '#282624',
                    '& .color': {
                      color: '#ED744E',
                    },
                  }}
                >
                  {review.text}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        width: '56px',
                        height: '56px',
                        backgroundSize: 'contain',
                        backgroundImage: `url(${review.image})`,
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir400',
                        fontSize: '20px',
                        lineHeight: 'normal',
                        color: '#908C89',
                        userSelect: 'none',
                      }}
                    >
                      {review.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      userSelect: 'none',
                      justifyContent: 'center',
                    }}
                  >
                    <img draggable={false} src={reviewStars} alt="review"></img>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={chart} alt="chart"></img>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Avenir600',
            fontSize: '56px',
            color: '#FFF',
            position: 'absolute',
            bottom: 0,
          }}
        >
          87%
        </Typography>
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir400',
          fontSize: '20px',
          color: '#728879',
          padding: '20px 20px 50px',
          lineHeight: 'normal',
          '& .plan': {
            color: '#FFF',
            textDecoration: 'underline',
            textDecorationColor: '#6DB231',
          },
        }}
      >
        of our users experienced an improvement in their quality{!isMobile && <br />} of life after finishing the{' '}
        <span className="plan">Refuse plan</span>
      </Typography>
    </Box>
  );
}

export default Reviews;
