import { Box, Typography, useMediaQuery } from '@mui/material';
import mob_bg from '../../../assets/images/main/intro_background_mob.webp';
import desk_bg from '../../../assets/images/main/intro_background_image.webp';
import StartTheQuizButton from './common/StartTheQuizButton';

function Hero() {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Box
      sx={{
        marginTop: '96px',
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '15px' : '20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${isMobile ? mob_bg : desk_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir500',
          fontSize: '16px',
          textAlign: 'center',
          userSelect: 'none',
          color: '#ED744E',
        }}
      >
        Ready for a Transformation?
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '32px',
          color: '#282624',
          lineHeight: 'normal',
          textAlign: 'center',
          userSelect: 'none',
          '.orange': {
            color: '#ED744E',
          },
        }}
      >
        {isMobile ? (
          <>
            Redefine Your
            <br />
            Relationship with
            <br />
            <span className="orange"> Alcohol</span>
          </>
        ) : (
          <>
            Redefine Your Relationship
            <br />
            with
            <span className="orange"> Alcohol</span>
          </>
        )}
      </Typography>
      <Box
        sx={{
          width: '218px',
        }}
      >
        <StartTheQuizButton />
      </Box>
      <Box
        sx={{
          height: '425px',
          marginTop: isMobile ? '140px' : '265px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          id="reviewsAnchor"
          sx={{
            scrollMargin: '84px',
            fontFamily: 'Avenir600',
            fontSize: '24px',
            textAlign: 'center',
            userSelect: 'none',
            color: '#FFF',
            margin: '0 20px',
          }}
        >
          Join the thousands of people we have {!isMobile && <br />}helped to break bad habits
        </Typography>
      </Box>
    </Box>
  );
}

export default Hero;
