import React from 'react';
import { Box, Grow } from '@mui/material';

function QuizContainer({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grow in style={{ transformOrigin: 'center top' }} timeout={500}>
        <Box
          sx={{
            maxWidth: '460px',
            width: '100%',
            padding: '0 20px',
          }}
        >
          {children}
        </Box>
      </Grow>
    </Box>
  );
}

export default QuizContainer;
