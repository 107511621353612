import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../App';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import {
  Reason,
  REASON_ANSWERS,
} from '@providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { getPathWithSearchParams } from '@utils/ulr';

function Step3({
  stepNumber = 3,
  stepsNumber = 19,
  backToMain = false,
}: {
  stepNumber?: number;
  stepsNumber?: number;
  backToMain?: boolean;
}) {
  const { setStep, setQuizData, quizData } = useQuizState();
  const navigate = useNavigate();
  const handleClick = (type: Reason) => {
    setQuizData((prev) => ({
      ...prev,
      reason: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          if (backToMain) {
            navigate(getPathWithSearchParams(Routes.MAIN));
          } else {
            setStep((prev) => prev - 1);
          }
        }}
        stepNumber={stepNumber}
        stepsNumber={stepsNumber}
      ></QuizHeader>
      <QuizQuestion
        text="What’s your top reason for wanting to change your drinking habits"
        description="Understanding your why will help us personalize your experience and tailor the program"
      />
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(REASON_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof REASON_ANSWERS)}
              variant={ButtonType.WHITE}
              text={REASON_ANSWERS[key as keyof typeof REASON_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step3;
