import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo.svg';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Summary1() {
  const { setStep } = useQuizState();
  return (
    <QuizStepBackgroundWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100dvh',
          overflow: 'auto',
        }}
      >
        <QuizContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
              paddingTop: '30px',
            }}
          >
            <img src={logo} width={72} height={72} />
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  textAlign: 'center',
                  color: '#ED744E',
                  marginBottom: '10px',
                  fontSize: '15px',
                }}
              >
                Ready for a Transformation?
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  color: '#282624',
                  fontSize: '24px',
                  textAlign: 'center',
                  userSelect: 'none',
                }}
              >
                Just a few moments to create your custom plan.
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  textAlign: 'center',
                  color: '#908C89',

                  '& .black': {
                    fontFamily: 'Avenir400',
                    textAlign: 'center',
                    color: '#282624',
                  },
                }}
              >
                Changing your relationships with alcohol is possible in just 10
                minutes a day!
              </Typography>
            </Box>

            <QuizButton
              onClick={() => {
                setStep((prev) => prev + 1);
              }}
              variant={ButtonType.ORANGE}
              text="Let's do it!"
            />
          </Box>
        </QuizContainer>
        <Box
          sx={{
            position: 'relative',
            flex: 1,
            maxWidth: '460px',
            width: '100vw',
            margin: '0 auto 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              margin: 'auto 0 0',
              position: 'relative',
              paddingTop: '120%',
              '& .image': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            }}
          >
            <img
              className="image"
              src="/refuse/images/quiz/free-person.webp"
              alt="image"
            />
          </Box>
        </Box>
      </Box>
    </QuizStepBackgroundWrapper>
  );
}

export default Summary1;
