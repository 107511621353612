import { useContext } from 'react';

import { UserContext } from './UserProvider';

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('use only inside provider');
  }

  return context;
}
