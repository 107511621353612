import { Box, Typography } from '@mui/material';

import ArrowBackSvg from '../../assets/svg/arrowBack.svg';
import logo from '../../assets/images/main/logo.svg';

function QuizHeader({
  stepNumber,
  stepsNumber,
  onBack,
  showSteps = true,
}: {
  stepsNumber: number;
  stepNumber: number;
  showSteps?: boolean;
  onBack: () => void;
}) {
  const fillPercentage = 100 * (stepNumber / stepsNumber);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          margin={'17px 0'}
          padding={'0 20px'}
        >
          <Box
            onClick={onBack}
            sx={{
              cursor: 'pointer',
              transition: 'all ease 0.3s',
              '&:hover': {
                opacity: 0.6,
              },
            }}
          >
            <img src={ArrowBackSvg} alt="arrow back"></img>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img width={'26px'} height={'26px'} src={logo} alt="logo"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '14px',
                color: '#282624',
                userSelect: 'none',
              }}
            >
              Refuse
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir500',
              fontSize: '16px',
              color: '#282624',
              userSelect: 'none',
              visibility: showSteps ? 'visible' : 'hidden',
              '.orange': {
                color: '#ED744E',
              },
            }}
          >
            <span className="orange">{stepNumber}</span>/{stepsNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '460px',
            margin: 'auto',
            height: '4px',
            background: `linear-gradient(to right,#ED744E 0%,#ED744E ${fillPercentage}%,#F9E9E0 ${fillPercentage}%,#F9E9E0 100%)`,
          }}
        ></Box>
      </Box>
    </Box>
  );
}

export default QuizHeader;
