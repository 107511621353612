import { Box } from '@mui/material';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import {
  DEEALING_WITH_HYPERTENSION_ANSWERS,
  DRINKING_EXPERIENCE_ANSWERS,
  DrinkingExperience
} from '../../../providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step14({
  stepNumber = 14,
  stepsNumber = 19,
  goNextStep,
}: {
  stepNumber?: number;
  stepsNumber?: number;
  goNextStep?: boolean;
}) {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: DrinkingExperience) => {
    setQuizData((prev) => ({
      ...prev,
      drinkingExperience: type,
    }));
      setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={stepNumber}
        stepsNumber={stepsNumber}
      ></QuizHeader>
      <QuizQuestion text="Have you ever drank more or for a longer period than you planned?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(DRINKING_EXPERIENCE_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof DRINKING_EXPERIENCE_ANSWERS)}
              variant={ButtonType.WHITE}
              text={DRINKING_EXPERIENCE_ANSWERS[key as keyof typeof DRINKING_EXPERIENCE_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step14;
