import React from 'react';
import { PaymentContext } from './PaymentProvider';

export function usePaymentState() {
    const context = React.useContext(PaymentContext);

    if (!context) {
        throw new Error('use only context provider!');
    }

    return context;
}
