import { Box, Link, useMediaQuery } from '@mui/material';
import { Routes } from '../../../../App';
import { APP_CONSTANTS } from '@utils/constants';

function TermsAndConditions({ isMainScreen }: { isMainScreen?: boolean }) {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-around',
        gap: isMobile ? '15px' : '30px',
        '& .link': {
          fontFamily: 'Avenir400',
          fontSize: '14px',
          color: isMainScreen ? '#728879' : '#282624',
          textDecorationColor: isMainScreen ? '#728879' : '#282624',
          transition: 'all 0.3s ease',
          whiteSpace: 'nowrap',
          textAlign: isMainScreen ? 'center' : undefined,
          '&:hover': {
            opacity: 0.8,
          },
        },
      }}
    >
      <Link target="_blank" href={Routes.TERMS} className="link">
        Terms and Conditions
      </Link>
      <Link target="_blank" href={Routes.PRIVACY} className="link">
        Privacy Policy
      </Link>
      <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
        Cookie Policy
      </Link>
      <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
        {APP_CONSTANTS.EMAIL}
      </Link>
    </Box>
  );
}

export default TermsAndConditions;
