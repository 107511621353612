import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

export interface IUser {
  uid: string;
  email: string;
  providerDataId?: string;
  providerId: 'google.com' | 'apple.com';
  isAppleAuth: boolean;
  isGoogleAuth: boolean;
}

export const UserContext = createContext<null | {
  user: null | IUser;
  setUser: Dispatch<SetStateAction<IUser | null>>;
}>(null);

UserContext.displayName = 'UserContext';

function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<null | IUser>(null);

  const value = { user, setUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
