import React from 'react';
import { Box } from '@mui/material';
import background from '../../assets/images/main/background_huge.webp';

function QuizStepBackgroundWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100dvh',
        background: '#FCF4EF'
      }}
    >
      {children}
    </Box>
  );
}

export default QuizStepBackgroundWrapper;
