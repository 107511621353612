import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Routes } from '../../../../App';

function StartTheQuizButton() {
  const navigate = useNavigate();

  return (
    <QuizButton
      className="start_quiz_btn"
      onClick={() => {
        navigate(getPathWithSearchParams(Routes.QUIZ));
      }}
      variant={ButtonType.ORANGE}
      text="Start the Quiz"
    />
  );
}

export default StartTheQuizButton;
