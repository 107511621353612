import { Routes } from '../App';

export const redirectToPaymentPage = (email: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set('redirectDomain', window.location.origin);
  params.set('redirectUserEmail', email);

  window.open(
    `https://palmistry.devroqapps.com${Routes.SUBSCRIPTION}?${params}`,
    '_self'
  );
};

export function getActiveSubscriptionConfig() {
  const pathAsArray = window.location.pathname.split('/').filter(Boolean);

  if (pathAsArray.includes('activate-me') || pathAsArray.includes('activate')) {
    return {
      account_code: pathAsArray[pathAsArray.length - 1],
    };
  } else {
    return null;
  }
}

export function removeRedirectParamsFromQuery() {
  const searchParams = new URLSearchParams(window.location.search);

  [
    'redirectTokenId',
    'redirectUserEmail',
    'redirectPlanId',
    'redirectRecurlyUri',
  ].forEach((param) => {
    searchParams.delete(param);
  });

  const newUrl = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams : ''}${window.location.hash}`;

  window.history.replaceState(null, '', newUrl);
}

export const redirectBackAfterPayment = (
  tokenId: string,
  email: string,
  planId: string,
  recurlyUri: string
) => {
  const searchParams = new URLSearchParams(window.location.search);

  const redirectDomain = searchParams.get('redirectDomain');

  if (redirectDomain) {
    searchParams.delete('redirectDomain');
    searchParams.set('redirectTokenId', tokenId);
    searchParams.set('redirectUserEmail', email);
    searchParams.set('redirectPlanId', planId);
    searchParams.set('redirectRecurlyUri', recurlyUri);

    const redirectUrl = `${redirectDomain}${Routes.SIGN_IN}?${searchParams}`;

    window.open(redirectUrl, '_self');
  }
};
