import { createContext, ReactNode, useEffect, useState } from 'react';
import {
  PaymentConfigType,
  PaymentPlanType,
} from '@providers/PaymentProvider/PaymentProvider.types';
import { getPaymentConfig } from '@api/requests';

export const PaymentConfigContext = createContext<null | {
  paymentConfig: null | PaymentConfigType;
  selectedPlan: null | PaymentPlanType;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PaymentPlanType | null>>;
}>(null);

function PaymentConfigProvider({ children }: { children: ReactNode }) {
  const [paymentConfig, setPaymentConfig] = useState<null | PaymentConfigType>(
    null
  );

  const [selectedPlan, setSelectedPlan] = useState<PaymentPlanType | null>(
    null
  );

  useEffect(() => {
    getPaymentConfig()
      .then((response) => {
        setPaymentConfig(response);
        setSelectedPlan(response.plans[0]);
      })
      .catch(console.error);
  }, []);

  const value = { paymentConfig, selectedPlan, setSelectedPlan };

  return (
    <PaymentConfigContext.Provider value={value}>
      {children}
    </PaymentConfigContext.Provider>
  );
}

export default PaymentConfigProvider;
