import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import {
  SYMPTOMS_ANSWERS,
  Symptoms,
  TRIGGERS_ANSWERS,
  Triggers,
} from '../../../providers/QuizProvider/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { useState } from 'react';

function Step8() {
  const { setStep, setQuizData } = useQuizState();
  const [selectedValues, setSelectedValues] = useState<Triggers[]>([]);

  const handleClick = (type: Triggers) => {
    const filteredValues = [...selectedValues];
    const isExists = filteredValues.find((el) => el === type);
    if (!isExists) {
      setSelectedValues([...filteredValues, type]);
    } else {
      const newValues = filteredValues.filter((el) => el !== type);
      setSelectedValues(newValues);
    }
  };

  const handleContinue = () => {
    setQuizData((prev) => ({
      ...prev,
      triggers: selectedValues,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={8}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="Are there specific triggers or situations that make it difficult to reduce your alcohol consumption?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '94px',
            '& .text-align-left': {
              textAlign: 'left',
            },
          }}
        >
          {Object.keys(TRIGGERS_ANSWERS).map((key) => (
            <QuizButton
              className="text-align-left"
              key={key}
              onClick={() => handleClick(key as keyof typeof TRIGGERS_ANSWERS)}
              variant={
                selectedValues.find((el) => el === key)
                  ? ButtonType.CHECKBOX_SELECTED
                  : ButtonType.CHECKBOX
              }
              text={TRIGGERS_ANSWERS[key as keyof typeof TRIGGERS_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
      {selectedValues.length > 0 && (
        <QuizButton
          onClick={handleContinue}
          variant={ButtonType.ORANGE_FIXED}
          text="Continue"
        ></QuizButton>
      )}
    </QuizStepBackgroundWrapper>
  );
}

export default Step8;
