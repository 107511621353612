import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import Logo from '../../assets/svg/logo.svg';
import LogoOutlined from '../../assets/svg/logo-outlined.svg';

import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { trackEvent } from '@api/requests';
import QuizButton, { ButtonType } from '../common/QuizButton';
import { APP_CONSTANTS } from '@utils/constants';
import { useUser } from '@providers/UserProvider';
import { getAttribution } from '@utils/attribution';
import { mergeSearchParamsIntoUrl } from '@utils/ulr';
import { filterFalsyValues } from '@utils/common';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { getPixelValue } from '@utils/pixel';
import { isIos } from '@utils/browser';

function DownloadApp() {
  const { userLocation } = useQuizState();

  const { paymentConfig } = usePaymentState();

  const { user } = useUser();

  useEffect(() => {
    trackEvent(`Download screen shown`, userLocation);
  }, []);

  function handleClick() {
    const token = user?.isAppleAuth
      ? user?.providerDataId ?? user?.uid
      : user?.uid;

    const attribution = getAttribution({
      ip: userLocation?.ip ?? '',
      country: userLocation?.country ?? '',
    });

    const link = mergeSearchParamsIntoUrl(
      isIos()
        ? paymentConfig?.download?.ios ?? APP_CONSTANTS.IOS_DOWNLOAD_APP_LINK
        : paymentConfig?.download?.android ??
            APP_CONSTANTS.ANDROID_DOWNLOAD_APP_LINK,
      filterFalsyValues({
        af_ad_id: attribution?.ad_id,
        af_adset_id: attribution?.adset_id,
        af_c_id: attribution?.campaign_id,
        af_ad: attribution?.ad_name,
        af_adset: attribution?.adset_name,
        c: attribution?.campaign_name,
        campaign: attribution?.campaign_name,
        af_sub1: paymentConfig?.pixel
          ? getPixelValue(paymentConfig?.pixel)
          : '',
        af_sub2: attribution?.fbc,
        af_sub3: attribution?.fbp,
        pid: 'quiz',
        af_sub4: token,
        af_sub5: user?.providerId?.slice(0, user?.providerId?.indexOf('.')),
      })
    );

    window.open(link, '_blank');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#FCF4EF',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '460px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '& .logo': {
              margin: '30px auto 0',
            },
          }}
        >
          <img
            className="logo"
            src={Logo}
            width={72}
            height={72}
            alt="logo"
          ></img>

          <Box
            sx={{
              flex: 1,
              margin: '20px 0 0',
              padding: '0px 20px 20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                fontSize: '15px',
                lineHeight: '20px',
                color: '#ED744E',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              Congratulations
            </Typography>
            <Typography
              sx={{
                margin: '10px 0 0',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                color: '#282624',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              We’ve automatically upgraded your account!{' '}
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '16px',
                lineHeight: '22px',
                color: '#908C89',
                textAlign: 'center',
                userSelect: 'none',
                marginTop: '10px',
              }}
            >
              You already have a Refuse account, so if you currently have the
              app installed, just open the app! If not, follow the directions
              below.
            </Typography>

            <Box
              sx={{
                width: '100%',
                height: '1px',
                background: 'rgba(240, 204, 185, 0.4)',
                margin: '40px 0',
              }}
            ></Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    lineHeight: '32px',
                    color: '#282624',
                  }}
                >
                  Step 1
                </Typography>

                <Typography
                  sx={{
                    margin: '5px 0 0',
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#282624',
                  }}
                >
                  Download application from{' '}
                  {isIos() ? 'AppStore' : 'Google Play'}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    background: 'white',
                    margin: '20px 0 0',
                    padding: '20px',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 8px 0px #DFD2C933',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '15px',
                    }}
                  >
                    <img src={LogoOutlined} alt="logo" />

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Avenir600',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#282624',
                        }}
                      >
                        Refuse
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir400',
                          fontSize: '12px',
                          lineHeight: '17px',
                          color: '#908C89',
                        }}
                      >
                        Neuroscience-based alcohol reducation app
                      </Typography>
                    </Box>
                  </Box>
                  <QuizButton
                    text="Download app"
                    variant={ButtonType.ORANGE}
                    onClick={handleClick}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    lineHeight: '32px',
                    color: '#282624',
                  }}
                >
                  Step 2
                </Typography>

                <Typography
                  sx={{
                    margin: '5px 0 0',
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#282624',
                  }}
                >
                  Open it and sign in to your account
                </Typography>

                <Box
                  sx={{
                    position: 'relative',
                    margin: '20px 0 0',
                    paddingTop: '54.62%',
                    background: 'white',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 8px 0px #DFD2C933',

                    '& .sign-in-image': {
                      position: 'absolute',
                      maxWidth: '100%',
                      width: '100%',
                      top: 0,
                      left: 0,
                      objectFit: 'cover',
                    },
                  }}
                >
                  <img
                    className="sign-in-image"
                    src="/refuse/images/sign-in.webp"
                    alt="sign-in"
                  />
                </Box>
              </Box>
            </Box>

            <Typography
              sx={{
                margin: '40px 0',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#282624',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              If you currently have the app installed, just open the app!
            </Typography>

            <Box
              sx={{
                width: '100%',
                margin: 'auto 0 0',
                padding: '20px 0',
                borderTop: '1px solid rgba(240, 204, 185, 0.4)',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  color: '#282624',
                  '& a': { color: '#ED744E', textDecoration: 'underline' },
                }}
              >
                Have a question? Reach our friendly support team here{' '}
                <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>here</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DownloadApp;
