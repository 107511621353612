import { useContext } from 'react';

import { PaymentConfigContext } from './PaymentConfigProvider';

export function usePaymentConfig() {
  const context = useContext(PaymentConfigContext);
  if (!context) {
    throw new Error('use only inside provider');
  }
  return context;
}
