import { QuizContext } from './QuizProvider';
import React from 'react';

export function useQuizState() {
    const context = React.useContext(QuizContext);

    if (!context) {
        throw new Error('use only context provider!');
    }

    return context;
}
