import { Box } from '@mui/material';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import { NEGATIVE_IMPACT_ANSWERS, NegativeImpact } from '../../../providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step18() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: NegativeImpact) => {
    setQuizData((prev) => ({ ...prev, negativeImpact: type }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={18}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="Has your drinking or hangovers negatively impacted your home life, family, work, or studies?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(NEGATIVE_IMPACT_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof NEGATIVE_IMPACT_ANSWERS)}
              variant={ButtonType.WHITE}
              text={NEGATIVE_IMPACT_ANSWERS[key as keyof typeof NEGATIVE_IMPACT_ANSWERS]}
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step18;
