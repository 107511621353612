import { initializeApp } from 'firebase/app';

import {
  browserSessionPersistence,
  GoogleAuthProvider,
  OAuthProvider,
  setPersistence,
  signInWithRedirect,
  getAuth,
} from 'firebase/auth';

const app = initializeApp({
  apiKey: 'AIzaSyB8jH2nCSdQJfRhhrWiA4a_lQquFywJ-EQ',
  authDomain:
    process.env.NODE_ENV === 'production'
      ? window.location.host
      : 'alcoholics-app.firebaseapp.com',
  projectId: 'alcoholics-app',
  storageBucket: 'alcoholics-app.appspot.com',
  messagingSenderId: '97893815982',
  appId: '1:97893815982:web:9bec80a56220b003783f3f',
  measurementId: 'G-T9G9BQYH78',
});

export const firebaseAuth = getAuth(app);

async function signInWithRedirectToApp(provider: any) {
  await setPersistence(firebaseAuth, browserSessionPersistence);
  return signInWithRedirect(firebaseAuth, provider).catch((error) => {
    console.error('firebase sign in error', error);
  });
}

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({ prompt: 'select_account' });
    return await signInWithRedirectToApp(provider);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signInWithApple = async () => {
  try {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return await signInWithRedirectToApp(provider);
  } catch (err) {
    return Promise.reject(err);
  }
};
