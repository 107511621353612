import { Box } from '@mui/material';
import { useState } from 'react';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import {
  PREFERRED_DRINKS_ANSWERS,
  PreferredDrinks,
} from '../../../providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step10() {
  const { setStep, setQuizData } = useQuizState();
  const [selectedValues, setSelectedValues] = useState<PreferredDrinks[]>([]);

  const handleClick = (type: PreferredDrinks) => {
    const filteredValues = [...selectedValues];
    const isExists = filteredValues.find((el) => el === type);
    if (!isExists) {
      setSelectedValues([...filteredValues, type]);
    } else {
      const newValues = filteredValues.filter((el) => el !== type);
      setSelectedValues(newValues);
    }
  };

  const handleContinue = () => {
    setQuizData((prev) => ({
      ...prev,
      prefferedDrinks: selectedValues,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={10}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="Select your preferred drinks"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '94px',
            '& .text-align-left': {
              textAlign: 'left',
            },
          }}
        >
          {Object.keys(PREFERRED_DRINKS_ANSWERS).map((key) => (
            <QuizButton
              className="text-align-left"
              key={key}
              onClick={() =>
                handleClick(key as keyof typeof PREFERRED_DRINKS_ANSWERS)
              }
              variant={
                selectedValues.find((el) => el === key)
                  ? ButtonType.CHECKBOX_SELECTED
                  : ButtonType.CHECKBOX
              }
              text={
                PREFERRED_DRINKS_ANSWERS[
                  key as keyof typeof PREFERRED_DRINKS_ANSWERS
                ]
              }
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
      {selectedValues.length > 0 && (
        <QuizButton
          onClick={handleContinue}
          variant={ButtonType.ORANGE_FIXED}
          text="Continue"
        ></QuizButton>
      )}
    </QuizStepBackgroundWrapper>
  );
}

export default Step10;
