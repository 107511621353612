import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo.svg';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import {
  Reason,
  REASON_ANSWERS,
} from '@providers/QuizProvider/QuizProvider.types';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizContainer from '../../common/QuizContainer';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Summary1() {
  const { setStep, quizData } = useQuizState();
  return (
    <QuizStepBackgroundWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100dvh',
          overflow: 'auto',
        }}
      >
        <QuizContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
              paddingTop: '30px',
            }}
          >
            <img src={logo} width={72} height={72} />
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  color: '#282624',
                  fontSize: '24px',
                  textAlign: 'center',
                  userSelect: 'none',
                }}
              >
                Your Journey Begins Here
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  textAlign: 'center',
                  color: '#908C89',
                  whiteSpace: 'pre-wrap',

                  '& .black': {
                    fontFamily: 'Avenir400',
                    textAlign: 'center',
                    color: '#282624',
                  },
                }}
              >
                We'll support and guide you towards your goal{' '}
                <span
                  style={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                  className="black"
                >
                  "{REASON_ANSWERS[quizData.reason ?? Reason.Savings]}"
                </span>{' '}
                in your program. Let's move on to a deeper exploration
              </Typography>
            </Box>

            <QuizButton
              onClick={() => {
                setStep((prev) => prev + 1);
              }}
              variant={ButtonType.ORANGE}
              text="Learn more"
            ></QuizButton>
          </Box>
        </QuizContainer>
        <Box
          sx={{
            flex: 1,
            maxWidth: '460px',
            width: '100vw',
            margin: '0 auto 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              margin: 'auto 0 0',
              position: 'relative',
              paddingTop: '120%',
              '& .image': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            }}
          >
            <img
              className="image"
              src="/refuse/images/quiz/bg_1.webp"
              alt="image"
            />
          </Box>
        </Box>
      </Box>
    </QuizStepBackgroundWrapper>
  );
}

export default Summary1;
