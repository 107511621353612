import { Box, Typography } from '@mui/material';
import logo from '@assets/images/main/logo.svg';
import React from 'react';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { redirectToPaymentPage } from '@utils/payment';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../App';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useNavigate } from 'react-router-dom';

export default function Advantages() {
  const { quizData } = useQuizState();
  const { isReadyAppleButton } = usePaymentState();
  const navigate = useNavigate();

  function onClick() {
    if (isReadyAppleButton) {
      redirectToPaymentPage(quizData.email);
    } else {
      navigate(getPathWithSearchParams(Routes.SUBSCRIPTION));
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#FCF4EF',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100dvh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '460px',
          width: '100%',
          margin: '0 auto',
          padding: '0 20px 60px',
        }}
      >
        <Box
          sx={{
            margin: '0 -20px',
            padding: '0 20px',
            background: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '30px 0 0',
            }}
          >
            <img
              draggable={false}
              width={'72px'}
              height={'72px'}
              src="/refuse/svg/logo.svg"
              alt="logo"
            />
            <Typography
              sx={{
                margin: '20px 0 0',
                fontFamily: 'Avenir500',
                fontSize: '15px',
                lineHeight: '21px',
                textAlign: 'center',
                color: '#ED744E',
              }}
            >
              What do you get?
            </Typography>
            <Typography
              sx={{
                margin: '10px 0 0',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                lineHeight: '32px',
                textAlign: 'center',
                color: '#282624',
                '& strong': {
                  color: '#ED744E',
                },
              }}
            >
              With Refuse you will be changed <strong>forever</strong>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            margin: '0 -20px',
            '& > img': {
              width: '100%',
            },
          }}
        >
          <img draggable={false} src="/refuse/svg/bottom-cap.svg" />
        </Box>
        <img
          draggable={false}
          style={{ margin: '20px auto' }}
          width={88}
          height={70}
          src="/refuse/images/advantages/arrow.webp"
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Personalized Program
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Our program is designed to match your habits and lifestyle, fitting
          your unique goals and needs for long-term success.{' '}
        </Typography>
        <Box sx={{ margin: '0 -20px' }}>
          <img
            draggable={false}
            style={{ maxWidth: '100%' }}
            src="/refuse/images/advantages/plan-image.webp"
          />
        </Box>
        <Box
          sx={{
            height: '1px',
            maxWidth: '215px',
            width: '100%',
            margin: '0 auto 40px',
            background: '#F0CCB9',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          24/7 AI Supports{' '}
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Watch you drinking habits change and celebrate your drink-free
          journey. Stay consistent and get instant support whenever you feel a
          craving
        </Typography>
        <Box sx={{ margin: '0 -20px' }}>
          <img
            draggable={false}
            style={{ maxWidth: '100%' }}
            src="/refuse/images/advantages/bot_banner.webp"
          />
        </Box>
        <Box
          sx={{
            height: '1px',
            maxWidth: '215px',
            width: '100%',
            margin: '0 auto 40px',
            background: '#F0CCB9',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Learn to live better
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Discover everything about healthy lifestyle, mindfulness, and
          psychology via comprehensive blogs to support the habit change that
          stays.
        </Typography>
        <Box sx={{ margin: '0 -20px' }}>
          <img
            draggable={false}
            style={{ maxWidth: '100%' }}
            src="/refuse/images/advantages/articles-image.webp"
          />
        </Box>
        <Box
          sx={{
            height: '1px',
            maxWidth: '215px',
            width: '100%',
            margin: '0 auto 40px',
            background: '#F0CCB9',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Science-Backed Approach{' '}
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Understand the neuroscience of drinking with evidence-based
          strategies. Take a look at drinking habbits from another angle and use
          knowledge to your advantage
        </Typography>
        <Box sx={{ margin: '0 -20px' }}>
          <img
            draggable={false}
            style={{ maxWidth: '100%' }}
            src="/refuse/images/advantages/science-backed.webp"
          />
        </Box>
        <Box
          sx={{
            height: '1px',
            maxWidth: '215px',
            width: '100%',
            margin: '0 auto 40px',
            background: '#F0CCB9',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Mocktail Recipes
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: '#282624',
          }}
        >
          Treat yourself to healthy, alcohol-free drinks using our selection of
          mocktail recipes to add a variety to your meals.
        </Typography>
        <Box sx={{ margin: '0 -20px' }}>
          <img
            draggable={false}
            style={{ maxWidth: '100%' }}
            src="/refuse/images/advantages/cocktails.webp"
          />
        </Box>
        <QuizButton
          text="Continue"
          variant={ButtonType.ORANGE_FIXED}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
}
