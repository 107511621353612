import { Box, Typography } from '@mui/material';

import QuizContainer from './QuizContainer';

function QuizQuestion({ text, description }: { text: string, description?: string }) {
  return (
    <>
      <QuizContainer>
        <Box
          sx={{
            margin: '30px 0',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              color: '#282624',
              fontSize: '24px',
              textAlign: 'center',
              userSelect: 'none'
            }}
          >
            {text}
          </Typography>
          {
            description &&
            <Box
              sx={{
                '& p': {
                  fontFamily: 'Avenir400',
                  textAlign: 'center',
                  color: '#908C89',
                },
                '& .black':{
                  fontFamily: 'Avenir400',
                  textAlign: 'center',
                  color: '#282624',
                }

              }}
            >
              <p dangerouslySetInnerHTML={{__html:description}}/>
            </Box>
          }
        </Box>

      </QuizContainer>
    </>
  );
}

export default QuizQuestion;
