import { LocationConfig } from '../../api/requests';

export enum RelationshipWithAlcohol {
  Quit = 'Quit Completely',
  Reduce = 'Reduce Consumption',
  Continue = 'Continue a sober lifestyle',
}

export const RELATIONSHIP_ANSWERS: Record<RelationshipWithAlcohol, string> = {
  [RelationshipWithAlcohol.Quit]: 'Quit Completely',
  [RelationshipWithAlcohol.Reduce]: 'Reduce Consumption',
  [RelationshipWithAlcohol.Continue]: 'Continue a sober lifestyle',
};

export enum Reason {
  Savings = 'Financial Savings',
  Personal = 'Achieve Personal Goals',
  MentalClarity = 'Boost Mental Clarity',
  Health = 'Improve Health',
  Stregten = 'Strengthen Relationship',
  Sleep = 'Improve sleep quality',
  Controle = 'Be in controle of my life',
}

export const REASON_ANSWERS: Record<Reason, string> = {
  [Reason.Savings]: 'Financial Savings',
  [Reason.Personal]: 'Achieve Personal Goals',
  [Reason.MentalClarity]: 'Boost Mental Clarity',
  [Reason.Health]: 'Improve Health',
  [Reason.Stregten]: 'Strengthen Relationship',
  [Reason.Sleep]: 'Improve sleep quality',
  [Reason.Controle]: 'Be in controle of my life',
};

export enum Areas {
  Hobbies = 'Cultivate new hobbies',
  StressManagement = 'Learn stress management',
  StrengthenRelationships = 'Strengthen Relationships',
  Productivity = 'Increase Productivity',
  Eating = 'Develop Healthy Eating Habits',
  MentalHealth = 'Boost Mental Health',
  Wight = 'Lose weight',
  Money = 'Save money',
}

export const AREAS_ANSWERS: Record<Areas, string> = {
  [Areas.Hobbies]: 'Cultivate new hobbies',
  [Areas.StressManagement]: 'Learn stress management',
  [Areas.StrengthenRelationships]: 'Strengthen Relationships',
  [Areas.Productivity]: 'Increase Productivity',
  [Areas.Eating]: 'Develop Healthy Eating Habits',
  [Areas.MentalHealth]: 'Boost Mental Health',
  [Areas.Wight]: 'Lose weight',
  [Areas.Money]: 'Save money',
};

export enum DrinksAmount {
  '0-2' = '0-2',
  '3-5' = '3-5',
  '5-7' = '5-7',
  '7+' = '7+',
}

export const DRINKS_AMOUNT_ANSWERS: Record<DrinksAmount, string> = {
  [DrinksAmount['0-2']]: '0-2',
  [DrinksAmount['3-5']]: '3-5',
  [DrinksAmount['5-7']]: '5-7',
  [DrinksAmount['7+']]: '7+',
};

export enum WeeklyBasisTarget {
  'None' = 'No drinks at all',
  '1-2' = '1-2',
  '3-5' = '3-5',
  'NotSure' = "I'm not sure yet",
}

export const WEEKLY_BASIS_TARGET_ANSWERS: Record<WeeklyBasisTarget, string> = {
  [WeeklyBasisTarget['None']]: 'No drinks at all',
  [WeeklyBasisTarget['1-2']]: '1-2',
  [WeeklyBasisTarget['3-5']]: '3-5',
  [WeeklyBasisTarget['NotSure']]: "I'm not sure yet",
};

export enum SpendAmount {
  '0-20' = 'up to $20',
  '20-50' = '$20-50',
  '50-150' = '$50-150',
  '150+' = '$150+',
}

export const SPEND_AMOUNT_ANSWERS: Record<SpendAmount, string> = {
  [SpendAmount['0-20']]: 'up to $20',
  [SpendAmount['20-50']]: '$20-50',
  [SpendAmount['50-150']]: '$50-150',
  [SpendAmount['150+']]: '$150+',
};

export enum Triggers {
  Stress = 'Stress at Work',
  Anxiety = 'Anxiety or Depression',
  Family = 'Family Conflicts',
  Boredom = 'Boredom',
  Celebrations = 'Celebrations',
  Loneliness = 'Loneliness',
  Social = 'Social Gatherings',
  None = 'None of the above',
}

export const TRIGGERS_ANSWERS: Record<Triggers, string> = {
  [Triggers.Stress]: 'Stress at Work',
  [Triggers.Anxiety]: 'Anxiety or Depression',
  [Triggers.Family]: 'Family Conflicts',
  [Triggers.Boredom]: 'Boredom',
  [Triggers.Celebrations]: 'Celebrations',
  [Triggers.Loneliness]: 'Loneliness',
  [Triggers.Social]: 'Social Gatherings',
  [Triggers.None]: 'None of the above',
};

export enum Frequency {
  OnceAMonthOrLess = 'Once a month or less',
  TwoToThreeTimesAMonth = '2-3 times a month',
  FourToFiveTimesAMonth = '4-5 times a month',
  FiveAndMoreTimesAMonth = '5 and more times a month',
}

export const FREQUENCY_ANSWERS: Record<Frequency, string> = {
  [Frequency.OnceAMonthOrLess]: 'Once a month or less',
  [Frequency.TwoToThreeTimesAMonth]: '2-3 times a month',
  [Frequency.FourToFiveTimesAMonth]: '4-5 times a month',
  [Frequency.FiveAndMoreTimesAMonth]: '5 and more times a month',
};

export enum PreferredDrinks {
  Beer = 'Beer',
  Wine = 'Wine',
  Cocktails = 'Cocktails',
  HardLiquors = 'Hard liquors (whiskey, gin, tequila, vodka, rum, etc.)',
  Other = 'Other',
}

export const PREFERRED_DRINKS_ANSWERS: Record<PreferredDrinks, string> = {
  [PreferredDrinks.Beer]: 'Beer',
  [PreferredDrinks.Wine]: 'Wine',
  [PreferredDrinks.Cocktails]: 'Cocktails',
  [PreferredDrinks.HardLiquors]:
    'Hard liquors (whiskey, gin, tequila, vodka, rum, etc.)',
  [PreferredDrinks.Other]: 'Other',
};

export enum Sex {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export const SEX_ANSWERS: Record<Sex, string> = {
  [Sex.Male]: 'Male',
  [Sex.Female]: 'Female',
  [Sex.Other]: 'Other',
};

export enum LivingArea {
  Country = 'The country',
  City = 'The city',
  Suburbs = 'The suburbs',
}

export const LIVING_AREA_ANSWERS: Record<LivingArea, string> = {
  [LivingArea.Country]: 'The country',
  [LivingArea.City]: 'The city',
  [LivingArea.Suburbs]: 'The suburbs',
};

export enum DrinkingExperience {
  Yes = 'Yes',
  No = 'No',
  PreferNotToAnswer = 'I prefer not to answer',
}

export const DRINKING_EXPERIENCE_ANSWERS: Record<DrinkingExperience, string> = {
  [DrinkingExperience.Yes]: 'Yes',
  [DrinkingExperience.No]: 'No',
  [DrinkingExperience.PreferNotToAnswer]: 'I prefer not to answer',
};

export enum QuitAttempts {
  Yes = 'Yes',
  No = 'No',
  PreferNotToAnswer = 'I prefer not to answer',
}

export const QUIT_ATTEMPTS_ANSWERS: Record<QuitAttempts, string> = {
  [QuitAttempts.Yes]: 'Yes',
  [QuitAttempts.No]: 'No',
  [QuitAttempts.PreferNotToAnswer]: 'I prefer not to answer',
};

export enum BlackoutExperience {
  Yes = 'Yes',
  No = 'No',
  PreferNotToAnswer = 'I prefer not to answer',
}

export const BLACKOUT_EXPERIENCE_ANSWERS: Record<BlackoutExperience, string> = {
  [BlackoutExperience.Yes]: 'Yes',
  [BlackoutExperience.No]: 'No',
  [BlackoutExperience.PreferNotToAnswer]: 'I prefer not to answer',
};

export enum ToleranceIncrease {
  Yes = 'Yes',
  No = 'No',
  PreferNotToAnswer = 'I prefer not to answer',
}

export const TOLERANCE_INCREASE_ANSWERS: Record<ToleranceIncrease, string> = {
  [ToleranceIncrease.Yes]: 'Yes',
  [ToleranceIncrease.No]: 'No',
  [ToleranceIncrease.PreferNotToAnswer]: 'I prefer not to answer',
};

export enum NegativeImpact {
  Yes = 'Yes',
  No = 'No',
  PreferNotToAnswer = 'I prefer not to answer',
}

export const NEGATIVE_IMPACT_ANSWERS: Record<NegativeImpact, string> = {
  [NegativeImpact.Yes]: 'Yes',
  [NegativeImpact.No]: 'No',
  [NegativeImpact.PreferNotToAnswer]: 'I prefer not to answer',
};

export enum Source {
  OnlineAds = 'Online Ads',
  NewsArticleBlog = 'News, Article, Blog',
  WebSearch = 'Web Search',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  AppStore = 'App Store',
  Reddit = 'Reddit',
  FriendsFamily = 'Friends, Family',
  Therapist = 'Therapist',
  HarvardMed = 'Harvard Med',
  YCombinator = 'Y Combinator',
  Other = 'Other',
}

export const SOURCE_ANSWERS: Record<Source, string> = {
  [Source.OnlineAds]: 'Online Ads',
  [Source.NewsArticleBlog]: 'News, Article, Blog',
  [Source.WebSearch]: 'Web Search',
  [Source.Instagram]: 'Instagram',
  [Source.TikTok]: 'TikTok',
  [Source.Facebook]: 'Facebook',
  [Source.AppStore]: 'App Store',
  [Source.Reddit]: 'Reddit',
  [Source.FriendsFamily]: 'Friends, Family',
  [Source.Therapist]: 'Therapist',
  [Source.HarvardMed]: 'Harvard Med',
  [Source.YCombinator]: 'Y Combinator',
  [Source.Other]: 'Other',
};

export enum Race {
  White = 'White',
  Indian = 'Indian',
  Pakistani = 'Pakistani',
  Bangladeshi = 'Bangladeshi',
  AfroCaribbean = 'Afro-Caribbean',
  BlackAfrican = 'Black African',
  Chinese = 'Chinese',
  OtherAsian = 'Other Asian',
  OtherEthnicGroup = 'Other ethnic group',
}

export const RACE_ANSWERS: Record<Race, string> = {
  [Race.White]: 'White',
  [Race.Indian]: 'Indian',
  [Race.Pakistani]: 'Pakistani',
  [Race.Bangladeshi]: 'Bangladeshi',
  [Race.AfroCaribbean]: 'Afro-Caribbean',
  [Race.BlackAfrican]: 'Black African',
  [Race.Chinese]: 'Chinese',
  [Race.OtherAsian]: 'Other Asian',
  [Race.OtherEthnicGroup]: 'Other ethnic group',
};
export enum HealthRelatedIssues {
  IHaveChronicConditions = 'IHaveChronicConditions',
  ImReasonablyHealthy = 'ImReasonablyHealthy',
  OccasionalColdsOrLowEnergyLevel = 'OccasionalColdsOrLowEnergyLevel',
  ImPerfectlyHealthy = 'ImPerfectlyHealthy',
  CantTell = 'CantTell',
}

export const HEALTH_RELATED_ISSUES_ANSWERS: Record<
  HealthRelatedIssues,
  string
> = {
  [HealthRelatedIssues.IHaveChronicConditions]: 'I have chronic conditions',
  [HealthRelatedIssues.ImReasonablyHealthy]: "I'm reasonably healthy",
  [HealthRelatedIssues.OccasionalColdsOrLowEnergyLevel]:
    'Occasional colds or low energy level',
  [HealthRelatedIssues.ImPerfectlyHealthy]: "I'm perfectly healthy",
  [HealthRelatedIssues.CantTell]: "Can't tell, I haven't been following",
};

export enum HeartCheckups {
  OnceAYear = 'OnceAYear',
  EveryCoupleOfYears = 'EveryCoupleOfYears',
  OnceIn5Years = 'OnceIn5Years',
  IHaventHadOneYet = 'IHaventHadOneYet',
}

export const HEART_CHECKUPS_ANSWERS: Record<HeartCheckups, string> = {
  [HeartCheckups.OnceAYear]: 'Once a year',
  [HeartCheckups.EveryCoupleOfYears]: 'Every couple of years',
  [HeartCheckups.OnceIn5Years]: 'Once in 5 years',
  [HeartCheckups.IHaventHadOneYet]: "I haven't had one yet",
};

export enum Symptoms {
  HighBloodPressure = 'HighBloodPressure',
  ChestDiscomfortOrPain = 'ChestDiscomfortOrPain',
  HighFatigue = 'HighFatigue',
  ShortnessOfBreath = 'ShortnessOfBreath',
  HighHeartRate = 'HighHeartRate',
  HeadachesDizziness = 'HeadachesDizziness',
  IDontRecallExactly = 'IDontRecallExactly',
  NoneOfTheAbove = 'NoneOfTheAbove',
}

export const SYMPTOMS_ANSWERS: Record<Symptoms, string> = {
  [Symptoms.HighBloodPressure]: 'High blood pressure',
  [Symptoms.ChestDiscomfortOrPain]: 'Chest discomfort or pain',
  [Symptoms.HighFatigue]: 'High fatigue',
  [Symptoms.ShortnessOfBreath]: 'Shortness of breath',
  [Symptoms.HighHeartRate]: 'High heart rate',
  [Symptoms.HeadachesDizziness]: 'Headaches, dizziness',
  [Symptoms.IDontRecallExactly]: "I don't recall exactly",
  [Symptoms.NoneOfTheAbove]: 'None of the above',
};

export enum FamilyHistoryOfHeartIssues {
  YesInMyBloodRelatives = 'YesInMyBloodRelatives',
  HaventHeardOfIt = 'HaventHeardOfIt',
}

export const FAMILY_HISTORY_ANSWERS: Record<
  FamilyHistoryOfHeartIssues,
  string
> = {
  [FamilyHistoryOfHeartIssues.YesInMyBloodRelatives]:
    'Yes, in my blood relatives',
  [FamilyHistoryOfHeartIssues.HaventHeardOfIt]: "Haven't heard of it",
};

export enum TotalCholesterolLevel {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_CHOLESTEROL_LEVEL_ANSWERS: Record<
  TotalCholesterolLevel,
  string
> = {
  [TotalCholesterolLevel.Low]: 'Low',
  [TotalCholesterolLevel.Normal]: 'Normal',
  [TotalCholesterolLevel.High]: 'High',
  [TotalCholesterolLevel.IDontKnow]: "I don't know",
};

export enum TotalHighDensityLipoprotein {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_HIGH_DENSITY_LIPOPROTEIN_ANSWERS: Record<
  TotalHighDensityLipoprotein,
  string
> = {
  [TotalHighDensityLipoprotein.Low]: 'Low',
  [TotalHighDensityLipoprotein.Normal]: 'Normal',
  [TotalHighDensityLipoprotein.High]: 'High',
  [TotalHighDensityLipoprotein.IDontKnow]: "I don't know",
};

export enum TotalLowDensityLipoprotein {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_LOW_DENSITY_LIPOPROTEIN_ANSWERS: Record<
  TotalLowDensityLipoprotein,
  string
> = {
  [TotalLowDensityLipoprotein.Low]: 'Low',
  [TotalLowDensityLipoprotein.Normal]: 'Normal',
  [TotalLowDensityLipoprotein.High]: 'High',
  [TotalLowDensityLipoprotein.IDontKnow]: "I don't know",
};

export enum BloodPressure {
  Below120 = 'Below120',
  Below130 = 'Below130',
  Below140 = 'Below140',
  Over140 = 'Over140',
  ImNotSure = 'ImNotSure',
}

export const BLOOD_PRESSUE_ANSWERS: Record<BloodPressure, string> = {
  [BloodPressure.Below120]: 'Below 120/80',
  [BloodPressure.Below130]: '120-129/80',
  [BloodPressure.Below140]: '130-139/80',
  [BloodPressure.Over140]: 'Over 140/90',
  [BloodPressure.ImNotSure]: "I'm not sure",
};

export enum DealingWithHypertension {
  Yes = 'Yes',
  No = 'No',
  IDontKnow = 'IDontKnow',
  IPreferNotToAnswer = 'IPreferNotToAnswer',
}

export const DEEALING_WITH_HYPERTENSION_ANSWERS: Record<
  DealingWithHypertension,
  string
> = {
  [DealingWithHypertension.Yes]: 'Yes',
  [DealingWithHypertension.No]: 'No',
  [DealingWithHypertension.IDontKnow]: "I don't know",
  [DealingWithHypertension.IPreferNotToAnswer]: 'I prefer not to answer',
};

export enum MedicationForHighBloodPressure {
  Yes = 'Yes',
  No = 'No',
  IDontKnow = 'IDontKnow',
  IPreferNotToAnswer = 'IPreferNotToAnswer',
}

export const MEDICATION_FOR_HIGH_BLOOD_PRESSURE_ANSWERS: Record<
  MedicationForHighBloodPressure,
  string
> = {
  [MedicationForHighBloodPressure.Yes]: 'Yes',
  [MedicationForHighBloodPressure.No]: 'No',
  [MedicationForHighBloodPressure.IDontKnow]: "I don't know",
  [MedicationForHighBloodPressure.IPreferNotToAnswer]: 'I prefer not to answer',
};

export enum DiabetesDiagnosedByADoctor {
  Yes = 'Yes',
  No = 'No',
}

export const DIABETES_DIAGNOSED_BY_A_DOCTOR_ANSWERS: Record<
  DiabetesDiagnosedByADoctor,
  string
> = {
  [DiabetesDiagnosedByADoctor.Yes]: 'Yes',
  [DiabetesDiagnosedByADoctor.No]: 'No',
};

export enum Lifestyle {
  Sedentary = 'Sedentary',
  LowActivity = 'LowActivity',
  ModeratelyActive = 'ModeratelyActive',
  PhysicallyDemanding = 'PhysicallyDemanding',
}

export const LIFESTYLE_ANSWERS: Record<Lifestyle, string> = {
  [Lifestyle.Sedentary]: 'Sedentary',
  [Lifestyle.LowActivity]: 'Low Activity (occasional walks)',
  [Lifestyle.ModeratelyActive]: 'Moderately Active (exercises 2-3 times/week)',
  [Lifestyle.PhysicallyDemanding]:
    'Physically demanding (intense training or hard work)',
};

export enum TypicalDay {
  IHaveNoTimeForMyself = 'AHaveNoTimeForMyself',
  ImVeryBusy = 'ImVeryBusy',
  MyScheduleIsPrettyFlexible = 'MyScheduleIsPrettyFlexible',
}

export const TYPICAL_DAY_ANSWERS: Record<TypicalDay, string> = {
  [TypicalDay.IHaveNoTimeForMyself]: 'I have no time for myself',
  [TypicalDay.ImVeryBusy]:
    "I'm very busy but managing to allocate some me-time",
  [TypicalDay.MyScheduleIsPrettyFlexible]: 'My schedule is pretty flexible',
};

export enum HoursOfSleep {
  LessThan5 = 'LessThan5',
  LessThan6 = 'LessThan6',
  LessThan7 = 'LessThan7',
  MoreThan8 = 'MoreThan8',
}

export const HOURS_OF_SLEEP_ANSWERS: Record<HoursOfSleep, string> = {
  [HoursOfSleep.LessThan5]: 'Less than 5 hours',
  [HoursOfSleep.LessThan6]: '5-6 hours',
  [HoursOfSleep.LessThan7]: '6-7 hours',
  [HoursOfSleep.MoreThan8]: '8+ hours',
};

export enum Smoke {
  Regularly = 'Regularly',
  Occasionally = 'Occasionally',
  IUsed = 'IUsed',
  IDont = 'IDont',
  OnlyE = 'OnlyE',
}

export const SMOKE_ANSWERS: Record<Smoke, string> = {
  [Smoke.Regularly]: 'Yes, regularly',
  [Smoke.Occasionally]: 'Yes, occasionally',
  [Smoke.IUsed]: 'I used to but quit',
  [Smoke.IDont]: "I don't smoke at all",
  [Smoke.OnlyE]: 'Only e-cigarettes or vaping',
};

export enum Alcohol {
  Regularly = 'Regularly',
  Occasionally = 'Occasionally',
  IUsed = 'IUsed',
  IDont = 'IDont',
}

export const ALCOHOL_ANSWERS: Record<Alcohol, string> = {
  [Alcohol.Regularly]: 'Yes, regularly',
  [Alcohol.Occasionally]: 'Yes, occasionally',
  [Alcohol.IUsed]: 'I used to but stopped',
  [Alcohol.IDont]: "I don't drink at all",
};

export enum Drugs {
  ITried = 'ITried',
  Currently = 'Currently',
  IUsed = 'IUsed',
  IDont = 'IDont',
  IPreferNotToDisclose = 'IPreferNotToDisclose',
}

export const DRUGS_ANSWERS: Record<Drugs, string> = {
  [Drugs.ITried]: "I've tried before",
  [Drugs.Currently]: 'Yes, currently',
  [Drugs.IUsed]: 'I used to but quit',
  [Drugs.IDont]: "I don't at all",
  [Drugs.IPreferNotToDisclose]: 'I prefer not to disclose',
};

export enum FeelEnergized {
  Yes = 'Yes',
  No = 'No',
  ItDepends = 'ItDepends',
}

export const FEEL_ENERGIZED_ANSWERS: Record<FeelEnergized, string> = {
  [FeelEnergized.Yes]: 'Yes',
  [FeelEnergized.No]: 'No',
  [FeelEnergized.ItDepends]: 'It depends',
};

export enum FatigueAndWeakness {
  Yes = 'Yes',
  Sometimes = 'Sometimes',
  UnableToDetermine = 'UnableToDetermine',
  HaventExperienced = 'HaventExperienced',
}

export const FATIGUE_AND_WEAKNESS_ANSWERS: Record<FatigueAndWeakness, string> =
  {
    [FatigueAndWeakness.Yes]:
      "Yes, I've been feeling tired and weak recently, which hinders my daily tasks.",
    [FatigueAndWeakness.Sometimes]:
      "Sometimes I experience mild fatigue and weakness, and I'm not always sure why.",
    [FatigueAndWeakness.HaventExperienced]:
      "I haven't experienced any tiredness or weakness lately.",
    [FatigueAndWeakness.UnableToDetermine]:
      "I'm unable to determine whether I've been feeling tired and weak recently as I haven't paid attention or don't know how to describe it.",
  };

export enum AbdomenPain {
  Discomfort = 'Discomfort',
  Heaviness = 'Heaviness',
  Pain = 'Pain',
  Throbbing = 'Throbbing',
  None = 'None',
}

export const ABDOMEN_PAIN_ANSWERS: Record<AbdomenPain, string> = {
  [AbdomenPain.Discomfort]: 'Discomfort in the abdomen',
  [AbdomenPain.Heaviness]: 'Heaviness in the abdomen',
  [AbdomenPain.Pain]: 'Pain in the abdomen',
  [AbdomenPain.Throbbing]: 'Throbbing pain in the shoulder',
  [AbdomenPain.None]: 'None of the above',
};

export enum ChangesInHeartRhythm {
  YesNoticed = 'YesNoticed',
  YesFelt = 'YesFelt',
  Unsure = 'Unsure',
}

export const CHANGES_IN_HEART_RHYTHM_ANSWERS: Record<
  ChangesInHeartRhythm,
  string
> = {
  [ChangesInHeartRhythm.YesNoticed]:
    'Yes, I have noticed changes in my heart rhythm.',
  [ChangesInHeartRhythm.YesFelt]: 'Yes, I have felt palpitations.',
  [ChangesInHeartRhythm.Unsure]: "Unsure, I haven't paid attention to it.",
};

export type WeightFormatType = 'lbs' | 'kg';
export type HeightFormatType = 'ft' | 'cm';
export enum AlertLevels {
  LOW = 'Low',
  NORMAL = 'Normal',
  HIGH = 'High',
}

export enum PaymentMethodType {
  APPLE = 'apple',
  CARD = 'card',
}

export type QuizData = {
  name: string;
  alcoholRelationship: RelationshipWithAlcohol | null;
  reason: Reason | null;
  areas: Areas[] | null;
  drinksAmount: DrinksAmount | null;
  weeklyTarget: WeeklyBasisTarget | null;
  spendAmount: SpendAmount | null;
  triggers: Triggers[] | null;
  frequency: Frequency | null;
  prefferedDrinks: PreferredDrinks[] | null;
  sex: Sex | null;
  age: string | undefined;
  livingArea: LivingArea | null;
  drinkingExperience: DrinkingExperience | null;
  quitAttempts: QuitAttempts | null;
  blackoutExperience: BlackoutExperience | null;
  toleranceIncrease: ToleranceIncrease | null;
  negativeImpact: NegativeImpact | null;
  source: Source | null;

  race: Race | null;
  weight: string | undefined;
  weightFormat: WeightFormatType;
  height: {
    cm: string | undefined;
    ft: string | undefined;
    in: string | undefined;
  };
  heightFormat: HeightFormatType;
  healthRelatedIssues: HealthRelatedIssues | undefined;
  heartCheckups: HeartCheckups | undefined;
  symptoms: Symptoms[] | undefined;
  familyHistoryOfHeartIssues: FamilyHistoryOfHeartIssues | undefined;
  totalCholesterolLevel: TotalCholesterolLevel | undefined;
  totalHighDensityLipoprotein: TotalHighDensityLipoprotein | undefined;
  totalLowDensityLipoprotein: TotalLowDensityLipoprotein | undefined;
  bloodPressure: BloodPressure | undefined;
  dealingWithHypertension: DealingWithHypertension | undefined;
  medicationForHighBloodPressure: MedicationForHighBloodPressure | undefined;
  diabetesDiagnosedByADoctor: DiabetesDiagnosedByADoctor | undefined;
  lifestyle: Lifestyle | undefined;
  typicalDay: TypicalDay | undefined;
  hoursOfSleep: HoursOfSleep | undefined;
  feelEnergized: FeelEnergized | undefined;
  fatigueAndWeakness: FatigueAndWeakness | undefined;
  abdomenPain: AbdomenPain | undefined;
  changesInHeartRhythm: ChangesInHeartRhythm | undefined;
  smoke: Smoke | undefined;
  alcohol: Alcohol | undefined;
  drugs: Drugs | undefined;
  email: string;
  isAgreed: boolean;
  isPaymentModalOpen: boolean;
  selectedPaymentMethod: PaymentMethodType;
};

const redirectEmail = new URLSearchParams(window.location.search).get(
  'redirectUserEmail'
);

export const DEFAUL_QUIZ_DATA: QuizData = {
  name: '',
  alcoholRelationship: null,
  reason: null,
  areas: null,
  drinksAmount: null,
  weeklyTarget: null,
  spendAmount: null,
  triggers: null,
  frequency: null,
  prefferedDrinks: null,
  sex: null,
  age: undefined,
  livingArea: null,
  drinkingExperience: null,
  quitAttempts: null,
  blackoutExperience: null,
  toleranceIncrease: null,
  negativeImpact: null,
  source: null,

  race: null,
  weight: undefined,
  weightFormat: 'lbs',
  height: {
    cm: undefined,
    ft: undefined,
    in: undefined,
  },
  heightFormat: 'ft',
  healthRelatedIssues: undefined,
  heartCheckups: undefined,
  symptoms: undefined,
  familyHistoryOfHeartIssues: undefined,
  totalCholesterolLevel: undefined,
  totalHighDensityLipoprotein: undefined,
  totalLowDensityLipoprotein: undefined,
  bloodPressure: undefined,
  dealingWithHypertension: undefined,
  medicationForHighBloodPressure: undefined,
  diabetesDiagnosedByADoctor: undefined,
  lifestyle: undefined,
  typicalDay: undefined,
  hoursOfSleep: undefined,
  feelEnergized: undefined,
  fatigueAndWeakness: undefined,
  abdomenPain: undefined,
  changesInHeartRhythm: undefined,
  smoke: undefined,
  alcohol: undefined,
  drugs: undefined,
  email: redirectEmail ?? '',
  isAgreed: true,
  isPaymentModalOpen: false,
  selectedPaymentMethod: PaymentMethodType.CARD,
};

export type QuizContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  quizData: QuizData;
  setQuizData: React.Dispatch<React.SetStateAction<QuizData>>;
  userLocation: LocationConfig | null;
};
