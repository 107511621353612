import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import logo from '../../../assets/images/main/logo.svg';
import progress_circle from '../../../assets/svg/progress_circle.svg';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../../App';
import { redirectToPaymentPage } from '@utils/payment';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function Processing() {
  const navigate = useNavigate();
  const { setStep, quizData } = useQuizState();
  const { isReadyAppleButton } = usePaymentState();
  const [progress, setProgress] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState('Do you smoke?');
  const intervalId = useRef<NodeJS.Timer | null>(null);
  const PROGRESS_DURATION = 100;
  const initialItems = [
    'Analyzing your responses',
    'Calculating your risk score',
    'Preparing your results',
    'Crafting an offer for you',
  ];
  const animations = {
    sx: {
      padding: 0,
      margin: 0,
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
  };
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    if (!openModal) {
      const interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, PROGRESS_DURATION);
      intervalId.current = interval;
      return () => {
        clearInterval(interval);
      };
    }
  }, [openModal]);

  useEffect(() => {
    if (progress >= 100) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        setTimeout(() => {
          const mode = new URLSearchParams(window.location.search).get('mode');
          if (mode === 'short') {
            if (isReadyAppleButton) {
              redirectToPaymentPage(quizData.email);
            } else {
              navigate(getPathWithSearchParams(Routes.SUBSCRIPTION));
            }
          } else {
            setStep((prev) => prev + 1);
          }
        }, 500);
      }
    } else if (progress === 25) {
      setOpenModal(true);
    } else if (progress === 26) {
      setItems((prev) => prev.slice(1));
    } else if (progress === 51) {
      setItems((prev) => prev.slice(1));
    } else if (progress === 65) {
      setModalText('Is there a history or bad habits in your family?');
      setOpenModal(true);
    } else if (progress === 83) {
      setItems((prev) => prev.slice(1));
    }
  }, [progress, setStep]);

  const getColor = (index: number) => {
    switch (index) {
      case 0: {
        return 'rgba(40, 38, 36, 1)';
      }
      case 1: {
        return 'rgba(40, 38, 36, 0.60)';
      }
      case 2: {
        return 'rgba(40, 38, 36, 0.40)';
      }
      default: {
        return 'rgba(40, 38, 36, 0.20)';
      }
    }
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '17px',
          }}
        >
          <img width={'26px'} height={'26px'} src={logo} alt="logo"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              userSelect: 'none',
              color: 'rgba(40, 38, 36, 1)',
            }}
          >
            Refuse
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '40px 0',
          }}
        >
          <img
            src={progress_circle}
            alt="processing"
            style={{
              position: 'absolute',
            }}
          ></img>
          <CircularProgress
            variant="determinate"
            value={progress}
            size={184}
            thickness={4}
            sx={{
              color: 'rgba(237, 116, 78, 1)',
              '& .MuiCircularProgress-circle': {
                strokeLinecap: 'round',
              },
            }}
          />
          <Typography
            sx={{
              position: 'absolute',
              fontFamily: 'Avenir500',
              fontSize: '40px',
              color: 'rgba(40, 38, 36, 1)',
            }}
          >
            {progress}%
          </Typography>
        </Box>
        <AnimatePresence>
          {[...items].map((item, index) => (
            <motion.div key={item} {...animations} layout>
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  textAlign: 'center',
                  fontSize: '16px',
                  marginBottom: '16px',
                  color: getColor(index),
                }}
              >
                {item}
              </Typography>
            </motion.div>
          ))}
        </AnimatePresence>
      </QuizContainer>
      {openModal && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(40, 38, 36, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '295px',
              padding: '20px',
              borderRadius: '20px',
              background: 'white',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '16px',
                lineHeight: '22px',
                color: 'rgba(40, 38, 36, 1)',
                textAlign: 'center',
              }}
            >
              {modalText}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                marginTop: '15px',
              }}
            >
              <QuizButton
                height="48px"
                onClick={() => {
                  setOpenModal(false);
                }}
                variant={ButtonType.ORANGE}
                text="Yes"
              ></QuizButton>
              <QuizButton
                height="48px"
                onClick={() => {
                  setOpenModal(false);
                }}
                variant={ButtonType.ORANGE}
                text="No"
              ></QuizButton>
            </Box>
          </Box>
        </Box>
      )}
    </QuizStepBackgroundWrapper>
  );
}

export default Processing;
