import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { APP_CONSTANTS } from '@utils/constants';
import { useUser } from '@providers/UserProvider';
import { getAttribution } from '@utils/attribution';
import { mergeSearchParamsIntoUrl } from '@utils/ulr';
import { filterFalsyValues } from '@utils/common';
import { getPixelValue } from '@utils/pixel';
import { isIos } from '@utils/browser';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import close from '../../../../assets/images/main/close.svg';
import leaf from '../../../../assets/images/main/mask_group.webp';
import app_store from '../../../../assets/images/main/app_store.svg';
import google_play from '../../../../assets/images/main/google_play.svg';

function DownloadAppButton() {
  const { userLocation } = useQuizState();

  const { paymentConfig } = usePaymentState();

  const { user } = useUser();

  const isMobile = useMediaQuery('(max-width:900px)');

  const [isDownloadOpen, setDownloadOpen] = useState(false);

  function handleClick(selectedLink?: 'ios' | 'android') {
    const token = user?.isAppleAuth ? user?.providerDataId ?? user?.uid : user?.uid;

    const attribution = getAttribution({
      ip: userLocation?.ip ?? '',
      country: userLocation?.country ?? '',
    });
    const getLink = () => {
      switch (selectedLink) {
        case 'ios': {
          return paymentConfig?.download?.ios ?? APP_CONSTANTS.IOS_DOWNLOAD_APP_LINK;
        }
        case 'android': {
          return paymentConfig?.download?.android ?? APP_CONSTANTS.ANDROID_DOWNLOAD_APP_LINK;
        }
        default: {
          return isIos()
            ? paymentConfig?.download?.ios ?? APP_CONSTANTS.IOS_DOWNLOAD_APP_LINK
            : paymentConfig?.download?.android ?? APP_CONSTANTS.ANDROID_DOWNLOAD_APP_LINK;
        }
      }
    };
    const link = mergeSearchParamsIntoUrl(
      getLink(),
      filterFalsyValues({
        af_ad_id: attribution?.ad_id,
        af_adset_id: attribution?.adset_id,
        af_c_id: attribution?.campaign_id,
        af_ad: attribution?.ad_name,
        af_adset: attribution?.adset_name,
        c: attribution?.campaign_name,
        campaign: attribution?.campaign_name,
        af_sub1: paymentConfig?.pixel ? getPixelValue(paymentConfig?.pixel) : '',
        af_sub2: attribution?.fbc,
        af_sub3: attribution?.fbp,
        pid: 'quiz',
        af_sub4: token,
        af_sub5: user?.providerId?.slice(0, user?.providerId?.indexOf('.')),
      })
    );

    window.open(link, '_blank');
  }

  return (
    <>
      <QuizButton
        className="download_app_btn"
        text="Download app"
        variant={ButtonType.WHITE}
        onClick={isMobile ? () => handleClick() : () => setDownloadOpen(true)}
      />
      {isDownloadOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: '#FFF',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              position: 'fixed',
              top: '40px',
              right: '40px',
              cursor: 'pointer',
            }}
            width={34}
            onClick={() => setDownloadOpen(false)}
            src={close}
            alt="close"
          ></img>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <img width={242} height={412} src={leaf} alt="leaf"></img>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '536px',
                margin: '0 20px',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Avenir600',
                  fontSize: '56px',
                  color: '#282624',
                  lineHeight: 'normal',
                }}
              >
                Download the Refuse app
              </Typography>
              <Box
                onClick={() => handleClick('ios')}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '30px',
                  background: '#FCF4EF',
                  padding: '30px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                  marginTop: '28px',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    color: '#282624',
                    lineHeight: 'normal',
                  }}
                >
                  iOS application
                </Typography>
                <img width={202} src={app_store} alt="app_store"></img>
              </Box>
              <Box
                onClick={() => handleClick('android')}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '30px',
                  background: '#FCF4EF',
                  padding: '30px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    color: '#282624',
                    lineHeight: 'normal',
                  }}
                >
                  Android application
                </Typography>
                <img width={202} src={google_play} alt="google_play"></img>
              </Box>
            </Box>
            <img style={{ transform: 'scaleX(-1)' }} width={242} height={412} src={leaf} alt="leaf"></img>
          </Box>
        </Box>
      )}
    </>
  );
}

export default DownloadAppButton;
