export default [
  '/refuse/images/bg.webp',
  '/refuse/images/congrats.webp',
  '/refuse/images/sign-in.webp',

  '/refuse/images/quiz/bg_1.webp',
  '/refuse/images/quiz/Chart.webp',
  '/refuse/images/quiz/free-person.webp',
  '/refuse/images/quiz/goal.webp',
  '/refuse/images/quiz/habits.webp',

  '/refuse/images/subscription/free-person-footer.webp',
  '/refuse/images/subscription/results.svg',

  '/refuse/svg/checkmark.svg',
  '/refuse/images/subscription/fire.webp',

  '/refuse/svg/bottom-cap.svg',
  '/refuse/svg/logo.svg',
  '/refuse/images/advantages/arrow.webp',
  '/refuse/images/advantages/articles-image.webp',
  '/refuse/images/advantages/bot_banner.webp',
  '/refuse/images/advantages/plan-image.webp',
  '/refuse/images/advantages/science-backed.webp',
  '/refuse/images/advantages/cocktails.webp',
];
