import { PropsWithChildren } from 'react';

import { RecurlyOptions } from '@recurly/recurly-js';
import { ElementsProps } from '@recurly/react-recurly';
import { APP_URLS } from '@utils/constants';
import { PaymentConfigType } from '@providers/PaymentProvider/PaymentProvider.types';
import { getAttribution } from '@utils/attribution';
import {
  SubscribeRecurlyError,
  SubscribeRecurlySecure3d,
  SubscribeRecurlySuccess,
} from '@models/recurly';
import { filterFalsyValues, getParamByKey } from '@utils/common';

export type RecurlyProviderType = RecurlyOptions & PropsWithChildren;

export type ElementType = ElementsProps & PropsWithChildren;

export interface LocationConfig {
  ip: string | null;
  country: string | null;
}

export async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const response: Response = await fetch(input, init);

  if (!response.ok || response.status !== 200) {
    const data = await response.json();
    return Promise.reject(data);
  }

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  const data = await response.json();

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    return data;
  }

  throw new FetchError({
    message: response.statusText,
    response,
    data,
  });
}

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };

  constructor({
    message,
    response,
    data,
  }: {
    message: string;
    response: Response;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.response = response;
    this.data = data ?? { message: message };
  }
}

export const getPaymentConfig = async (): Promise<PaymentConfigType> => {
  let url = '';
  let basePath = '';

  if (process.env.NODE_ENV === 'production') {
    const redirectDomain = getParamByKey('redirectDomain');

    if (redirectDomain) {
      url = redirectDomain + '/refuse/' + window.location.search;
      basePath = redirectDomain;
    } else {
      url = window.location.href;
    }
  } else {
    url = `${APP_URLS.DEV_APP_BASE_PATH}` + window.location.search;
  }

  return fetcher(basePath + '/refuse/api/quiz/config', {
    method: 'POST',
    body: JSON.stringify({
      url: url,
    }),
    headers: {
      'Content-Type': 'text/plain',
    },
  });
};

export const getLocationData = async (): Promise<LocationConfig> =>
  fetcher(`${window.location.origin}/remote-addr`, {
    method: 'GET',
  });

export async function createSubscriptionNoUser({
  url,
  email,
  plan_id,
  token_id,
  attribution,
}: {
  url: string;
  email: string;
  plan_id: string | undefined;
  token_id: string;
  attribution: Record<string, any>;
}): Promise<
  SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d
> {
  return fetcher(`${url}/create-subscription-no-user`, {
    method: 'POST',
    body: JSON.stringify({
      plan_id,
      token_id,
      email,
      attribution,
    }),

    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export async function createSubscriptionRecurly({
  url,
  plan_id,
  subscription_id,
  email,
  firebase_id,
  apple_token,
  attribution,
}: {
  url: string;
  plan_id: string;
  subscription_id: string;
  email: string;
  firebase_id?: string;
  apple_token?: string;
  attribution: Record<string, any>;
}): Promise<
  SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d
> {
  return fetcher(`${url}/subscription-finalize`, {
    method: 'POST',
    body: JSON.stringify({
      plan_id,
      subscription_id,
      email,
      firebase_id,
      apple_token,
      attribution,
    }),
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export async function activateSubscriptionRecurly({
  url,
  account_code,
  email,
  firebase_id,
  apple_token,
}: {
  url: string;
  account_code?: string;
  email: string;
  firebase_id?: string;
  apple_token?: string;
}): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError> {
  return fetcher(`${url}/subscription-activate`, {
    method: 'POST',
    body: JSON.stringify({
      account_code,
      email,
      firebase_id,
      apple_token,
    }),
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export const trackEvent = async (
  event: string,
  location?: LocationConfig | null,
  options?: Record<string, any>
): Promise<void> => {
  const userLocation = location ?? (await getLocationData());

  const {
    campaign_id,
    campaign_name,
    country_code,
    client_ip_address,
    ...rest
  } = getAttribution({
    ip: userLocation.ip ?? '',
    country: userLocation?.country ?? '',
  });

  rest.campaignId = getParamByKey('campaign_id');
  rest.campaign = getParamByKey('campaign_name');
  rest.ip = userLocation.ip ?? '';
  rest.country = userLocation.country ?? '';

  const body = {
    userAgent: window.navigator.userAgent,
    event: event,
    ...filterFalsyValues(rest),
    ...filterFalsyValues(options ?? {}),
  };

  return fetcher(`${window.location.origin}/event`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
