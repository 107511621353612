import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import {
  DRINKING_EXPERIENCE_ANSWERS,
  MEDICATION_FOR_HIGH_BLOOD_PRESSURE_ANSWERS,
  MedicationForHighBloodPressure,
  QUIT_ATTEMPTS_ANSWERS,
  QuitAttempts,
} from '../../../providers/QuizProvider/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step15() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: QuitAttempts) => {
    setQuizData((prev) => ({
      ...prev,
      quitAttempts: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={15}
        stepsNumber={19}
      ></QuizHeader>
      <QuizQuestion text="Have you made repeated attempts to cut down or quit drinking but couldn't?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '30px',
          }}
        >
          {Object.keys(QUIT_ATTEMPTS_ANSWERS).map((key) => (
            <QuizButton
              key={key}
              onClick={() => handleClick(key as keyof typeof QUIT_ATTEMPTS_ANSWERS)}
              variant={ButtonType.WHITE}
              text={
                QUIT_ATTEMPTS_ANSWERS[
                  key as keyof typeof QUIT_ATTEMPTS_ANSWERS
                ]
              }
            ></QuizButton>
          ))}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step15;
