export const getPathWithSearchParams = (path: string) =>
  path + window.location.search;

export function dividePathnameAndSearch(url: string) {
  const questionMarkIndex = url.indexOf('?');
  if (questionMarkIndex === -1) {
    return [url, ''];
  } else {
    const pathname = url.slice(0, questionMarkIndex);
    const search = url.slice(questionMarkIndex);

    return [pathname, search];
  }
}

export function mergeSearchParamsIntoUrl(
  url: string,
  params: URLSearchParams | Record<string, any>
) {
  const [pathname, search] = dividePathnameAndSearch(url);
  const searchParams = new URLSearchParams(search);

  if (params instanceof URLSearchParams) {
    params.forEach((key, value) => {
      searchParams.set(key, value);
    });
  } else {
    Object.keys(params).forEach((key) => {
      const value = params[key];

      if (value === null || value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, String(value));
      }
    });
  }

  return [pathname, searchParams.toString()].filter(Boolean).join('?');
}
