import { useState, ReactNode, createContext, FC, useEffect } from 'react';

import {
  DEFAUL_QUIZ_DATA,
  QuizContextType,
  QuizData,
} from './QuizProvider.types';
import { LocationConfig, getLocationData } from '../../api/requests';

export const QuizContext = createContext<QuizContextType | null>(null);

export const QuizProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(Number(params.get('startFrom')) || 1);
  const [quizData, setQuizData] = useState<QuizData>(DEFAUL_QUIZ_DATA);
  const [userLocation, setUserLocation] = useState<LocationConfig | null>(null);

  useEffect(() => {
    getLocationData()
      .then((response) => {
        setUserLocation(response);
      })
      .catch(console.error);
  }, []);

  const value: QuizContextType = {
    step,
    setStep,
    quizData,
    setQuizData,
    userLocation,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
